import {
  Editor,
  Transforms,
  Range,
  Element as SlateElement,
  Descendant,
} from "slate";
import isUrl from "is-url";

import escapeHtml from "escape-html";
import { Text } from "slate";
import { jsx } from "slate-hyperscript";
import { TCustomElement } from "../components/plateRte/PlateRte.types";
import { TElement } from "@udecode/plate-common";
import { ELEMENT_BLOCKQUOTE } from "@udecode/plate-block-quote";
import { ELEMENT_PARAGRAPH } from "@udecode/plate-paragraph";
import {
  ELEMENT_H2,
  ELEMENT_H3,
  ELEMENT_H4,
  ELEMENT_H5,
  ELEMENT_H6,
} from "@udecode/plate-heading";
import { ELEMENT_LI, ELEMENT_OL, ELEMENT_UL } from "@udecode/plate-list";
import { ELEMENT_LINK } from "@udecode/plate-link";
import { ELEMENT_IMAGE } from "@udecode/plate-media";
import { ELEMENT_HR } from "@udecode/plate-horizontal-rule";

export const emptySlateValue: TElement[] = [
  {
    type: "p",
    children: [{ text: "" }],
  },
];

const ELEMENT_TAGS = {
  A: (el: Element) => ({ type: "link", url: el.getAttribute("href") }),
  BLOCKQUOTE: () => ({ type: "quote" }),
  H2: () => ({ type: "heading-two" }),
  H3: () => ({ type: "heading-three" }),
  H4: () => ({ type: "heading-four" }),
  H5: () => ({ type: "heading-five" }),
  H6: () => ({ type: "heading-six" }),
  IMG: (el: Element) => ({ type: "image", url: el.getAttribute("src") }),
  VID: (el: Element) => ({ type: "video", url: el.getAttribute("src") }),
  IFRAME: (el: Element) => ({ type: "youtube", url: el.getAttribute("src") }),
  LI: () => ({ type: "list-item" }),
  OL: () => ({ type: "numbered-list" }),
  P: () => ({ type: "paragraph" }),
  PRE: () => ({ type: "code" }),
  UL: () => ({ type: "bulleted-list" }),
};

// COMPAT: `B` is omitted here because Google Docs uses `<b>` in weird ways.
const TEXT_TAGS = {
  CODE: () => ({ code: true }),
  DEL: () => ({ strikethrough: true }),
  EM: () => ({ italic: true }),
  I: () => ({ italic: true }),
  S: () => ({ strikethrough: true }),
  STRONG: () => ({ bold: true }),
  U: () => ({ underline: true }),
};

export const isSlateEmpty = (value: TElement[] | undefined) => {
  if (!value) {
    return true;
  }
  if (value.length === 1) {
    if (
      //@ts-ignore
      value[0].children?.length === 1 &&
      //@ts-ignore
      value[0].children[0]?.text === ""
    ) {
      return true;
    }
  }
  return false;
};

export const withLinks = (editor: Editor) => {
  const { insertData, insertText, isInline, isElementReadOnly, isSelectable } =
    editor;

  editor.isInline = (element) => {
    return element.type === "link" ? true : isInline(element);
  };

  editor.isElementReadOnly = (element) => isElementReadOnly(element);

  editor.isSelectable = (element) => isSelectable(element);

  editor.insertText = (text) => {
    if (text && isUrl(text)) {
      wrapLink(editor, text);
    } else {
      insertText(text);
    }
  };

  editor.insertData = (data) => {
    const text = data.getData("text/plain");

    if (text && isUrl(text)) {
      wrapLink(editor, text);
    } else {
      insertData(data);
    }
  };

  return editor;
};

export const insertLink = (editor: Editor, url: string) => {
  if (editor.selection) {
    wrapLink(editor, url);
  }
};

export const isLinkActive = (editor: Editor) => {
  const [link] = Editor.nodes(editor, {
    match: (n) =>
      !Editor.isEditor(n) && SlateElement.isElement(n) && n.type === "link",
  });
  return !!link;
};

export const isMediaActive = (editor: Editor) => {
  const [media] = Editor.nodes(editor, {
    match: (n) =>
      !Editor.isEditor(n) &&
      SlateElement.isElement(n) &&
      (n.type === "video" || n.type === "image"),
  });
  return !!media;
};

const wrapLink = (editor: Editor, url: string) => {
  // console.log("Wrap");
  if (isLinkActive(editor)) {
    unwrapLink(editor);
  }

  const { selection } = editor;
  const isCollapsed = selection && Range.isCollapsed(selection);
  const link: TCustomElement = {
    type: "link",
    url,
    children: isCollapsed ? [{ text: url }] : [],
  };

  if (isCollapsed) {
    Transforms.insertNodes(editor, link);
  } else {
    Transforms.wrapNodes(editor, link, { split: true });
    Transforms.collapse(editor, { edge: "end" });
  }
};

export const unwrapLink = (editor: Editor) => {
  Transforms.unwrapNodes(editor, {
    match: (n) =>
      !Editor.isEditor(n) && SlateElement.isElement(n) && n.type === "link",
  });
};

export const withMedia = (editor: Editor) => {
  const { insertData, isVoid } = editor;

  editor.isVoid = (element) => {
    return element.type === "image" ||
      element.type === "video" ||
      element.type === "youtube"
      ? true
      : isVoid(element);
  };

  editor.insertData = (data) => {
    const text = data.getData("text/plain");

    // const { files } = data;

    // if (files && files.length > 0) {
    //   for (const file of files) {
    //     const reader = new FileReader();
    //     const [mime] = file.type.split("/");

    //     if (mime === "image") {
    //       reader.addEventListener("load", () => {
    //         const url = reader.result;
    //         insertMedia(editor, url as string, "image");
    //       });
    //       reader.readAsDataURL(file);
    //     }
    //   }
    // } else if (isImageUrl(text)) {
    if (isMediaUrl(text)) {
      // insertMedia(editor, text, isMediaUrl(text) as "image" | "video");
    } else {
      insertData(data);
    }
  };

  return editor;
};

export const insertMedia = (
  editor: Editor,
  url: string,
  type: "image" | "video" | "youtube"
) => {
  const text = { text: "" };
  const media: TCustomElement[] = [
    { type, url, children: [text] },
    { type: "paragraph", children: [{ text: "" }] },
  ];
  Transforms.insertNodes(editor, media);
};

const isMediaUrl = (url: string) => {
  if (!url) return false;
  // if (!isUrl(url)) return false;
  // const ext = new URL(url).pathname.split(".").pop();
  // if (imageExtensions.includes(ext!)) return "image";
  // else if (videoExtensions.includes(ext!)) return "video";
  else return false;
};

export const rteSerialize = (node: TElement) => {
  if (Text.isText(node)) {
    let string = escapeHtml(node.text);
    if (node.bold) {
      string = `<strong>${string}</strong>`;
    }
    if (node.italic) {
      string = `<em>${string}</em>`;
    }
    if (node.underline) {
      string = `<u>${string}</u>`;
    }
    if (node.code) {
      string = `<code>${string}</code>`;
    }
    return string;
  }
  const map_obj = Array.isArray(node) ? node : node.children;
  const children: string = map_obj.map((n: any) => rteSerialize(n)).join("");

  switch (node.type) {
    case ELEMENT_BLOCKQUOTE:
      return `<blockquote><p>${children}</p></blockquote>`;
    // case "code":
    //   return `<code><p>${children}</p></code>`;
    case ELEMENT_PARAGRAPH:
      return `<p>${children}</p>`;
    // case "heading-one":
    //   return `<h1>${children}</h1>`;
    case ELEMENT_H2:
      return `<h2>${children}</h2>`;
    case ELEMENT_H3:
      return `<h3>${children}</h3>`;
    case ELEMENT_H4:
      return `<h4>${children}</h4>`;
    case ELEMENT_H5:
      return `<h5>${children}</h5>`;
    case ELEMENT_H6:
      return `<h6>${children}</h6>`;
    case ELEMENT_LINK:
      //@ts-ignore
      return `<a href="${escapeHtml(node.url)}">${children}</a>`;
    case ELEMENT_IMAGE:
      console.log(node);
      //@ts-ignore
      return `<img alt="${escapeHtml(node.alt)}" src="${escapeHtml(
        //@ts-ignore
        node.url
      )}"/>`;
    // case "video":
    //   return `<video alt="" src="${escapeHtml(node.url)}"/>`;
    // case "youtube":
    //   return `<iframe alt="" src="${escapeHtml(
    //     node.url
    //   )}?rel=0&controls=0&modestbranding=1" frameBorder="0"/>`;
    case ELEMENT_OL:
      return `<ol>${children}</ol>`;
    case ELEMENT_UL:
      return `<ul>${children}</ul>`;
    case ELEMENT_LI:
      return `<li>${children}</li>`;
    case ELEMENT_HR:
      return `<hr/>`;
    default:
      return children;
  }
};

export const rteDeserialize = (el: Element | ChildNode) => {
  if (el.nodeType === 3) {
    return el.textContent;
  } else if (el.nodeType !== 1) {
    return null;
  }

  const { nodeName } = el;
  let parent: Element | ChildNode = el;

  if (
    nodeName === "PRE" &&
    el.childNodes[0] &&
    el.childNodes[0].nodeName === "CODE"
  ) {
    parent = el.childNodes[0];
  }

  const children: (string | Element | Descendant | null)[] = Array.from(
    parent.childNodes
  )
    .map(rteDeserialize)
    .flat();

  if (!children.length) {
    children.push("");
  }

  switch (el.nodeName) {
    case "BODY":
      return jsx("fragment", {}, children);
    case "BR":
      return "\n";
    default:
      //@ts-ignore
      if (ELEMENT_TAGS[nodeName]) {
        //@ts-ignore
        const attrs = ELEMENT_TAGS[nodeName](el);
        return jsx("element", attrs, children);
      }
      //@ts-ignore
      if (TEXT_TAGS[nodeName]) {
        //@ts-ignore
        const attrs = TEXT_TAGS[nodeName](el);
        return children.map((child) => jsx("text", attrs, child));
      }
  }

  return children;
};

export const parseRteValue = (data: string | null | undefined) => {
  if (!data) {
    return emptySlateValue;
  }

  const document = new DOMParser().parseFromString(
    data || "<p></p>",
    "text/html"
  );
  return rteDeserialize(document.body) as Descendant[];
};
