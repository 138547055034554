export const UNKNOWN_ERROR_STRING =
  "Došlo je do nepoznate greške! Pokušajte ponovno ili kontaktirajte podršku!";

export const ITEMS_PER_PAGE = 25;

export const ALLOWED_IMAGES_TYPES = ["image/jpeg", "image/png", "image/jpg"];

export const ALLOWED_DOCUMENTS_TYPES = [
  "application/pdf",
  "application/msword",
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  "application/vnd.ms-excel",
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
];

export const ALLOWED_DOCUMENT_EXTENSIONS = [
  "pdf",
  "doc",
  "docx",
  "xls",
  "xlsx",
];

export const ALLOWED_MEDIA_TYPES = [
  ...ALLOWED_IMAGES_TYPES,
  ...ALLOWED_DOCUMENTS_TYPES,
];

export const MAX_DOCUMENT_UPLOAD_SIZE = 100000000;
export const MAX_IMAGE_UPLOAD_SIZE = 8000000;

export const INPUT_POST_TITLE_MAX_LENGTH = 100;
export const INPUT_POST_SLUG_MAX_LENGTH = 80;

export const MSG_UNSAVED_CHANGES =
  "Imate neke nespremljene promjene. Molimo vas da ih ažurirate ili vratite te promjene i pokušate ponovno.";
export const MSG_GENERIC_ERROR =
  "Greška prilikom izvršavanja operacije. Osvježite stranicu i pokušajte ponovno ili kontaktirajte podršku.";
