import { createTypedDynamicFormInputs } from "../../../utils/formUtils";

export const initialInputData = createTypedDynamicFormInputs({
  email: {
    inputProps: {
      label: "Email",
      required: true,
      name: "email",
    },
    rule: "email",
  },
  password: {
    inputProps: {
      label: "Lozinka",
      required: true,
      type: "password",
      name: "password",
    },
  },
  remember: {
    inputProps: {
      label: "Zapamti me na 14 dana",
      name: "rememberMe",
    },
  },
});
