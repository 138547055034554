import { gql } from "@apollo/client";

export interface IUpdateAdmin {
  id: string;
  name: String;
}

export interface IUpdateAdminData {
  updateAdmin: IUpdateAdmin;
}

export interface IUpdateAdminVars {
  id: number;
  data: {
    name: string | undefined;
  };
}

export const UPDATE_ADMIN = gql`
  mutation updateAdmin($id: ID!, $data: AdminUpdateInput!) {
    updateAdmin(id: $id, data: $data) {
      id
      name
    }
  }
`;
