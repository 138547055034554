import { createTypedDynamicFormInputs } from "../../../utils/formUtils";

export const initialInputData = createTypedDynamicFormInputs({
  password: {
    inputProps: {
      label: "Nova lozinka",
      name: "newPassword",
      required: true,
      type: "password",
    },
  },
  passwordRepeat: {
    inputProps: {
      type: "password",
      name: "repeatPassword",
      required: true,
      label: "Ponovite novu lozinku",
    },
  },
});
