import { FC, useEffect } from "react";
import {
  DialogTitle,
  DialogContent,
  Button,
  TextField,
  DialogActions,
  Dialog,
  Typography,
} from "@mui/material";
// import { useStyles } from "./UpsertAdminDialog.styles";
import { useSnackbar } from "notistack";
import { useLazyQuery, useMutation } from "@apollo/client";
import { initialInputData } from "./UpdateMediaDialog.inputs";
import { useForm } from "../../../utils";
import {
  IMedia,
  IMediaData,
  IMediaVars,
  ONE_MEDIA,
} from "../../../apollo/queries";
import {
  IUpdateMediaData,
  IUpdateMediaVars,
  UPDATE_MEDIA,
} from "../../../apollo/mutations";
import { DataHandlerComponent, LoadingBackdrop } from "../../";

interface IProps {
  onClose: () => void;
  open: boolean;
  item: IMedia | undefined;
}

export const UpdateMediaDialog: FC<IProps> = (props) => {
  const { onClose, open, item } = props;
  const { enqueueSnackbar } = useSnackbar();

  const {
    inputFields,
    inputFieldNames,
    resetFields,
    validateForm,
    didValuesChange,
    getFormValuesFromFetchedData,
    handleDataToVar,
  } = useForm<keyof typeof initialInputData>(initialInputData);

  const [queryOneMedia, { data, error, loading }] = useLazyQuery<
    IMediaData,
    IMediaVars
  >(ONE_MEDIA, {});

  const [updateMediaMutation, { loading: loadingUpdateMediaMutation }] =
    useMutation<IUpdateMediaData, IUpdateMediaVars>(UPDATE_MEDIA, {
      onCompleted: () => {
        enqueueSnackbar("Medij je ažuriran.", {
          variant: "success",
        });
        resetFields();
        onClose();
      },
      onError: (err) => {
        enqueueSnackbar(err.message, {
          variant: "error",
        });
      },
    });

  useEffect(() => {
    if (open) {
      if (item?.id) {
        queryOneMedia({ variables: { id: +item.id } });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open, item?.id]);

  const clearFields = () => {
    resetFields();
  };

  const handleUpdateMedia = () => {
    if (!validateForm(inputFieldNames)) {
      return;
    }
    if (item?.id) {
      updateMediaMutation({
        variables: {
          id: +item.id,
          data: {
            title: handleDataToVar("title", "string", false),
            alt: handleDataToVar("alt", "string", false),
          },
        },
      });
    }
  };

  useEffect(() => {
    if (data?.oneMedia && open) {
      getFormValuesFromFetchedData(
        data.oneMedia,
        [
          {
            fromDataProperty: "title",
            toFormProperty: "title",
          },
          {
            fromDataProperty: "alt",
            toFormProperty: "alt",
          },
        ],
        false
      );
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, open]);

  return (
    <Dialog
      maxWidth="sm"
      fullWidth
      open={open}
      onClose={onClose}
      TransitionProps={{ onExited: clearFields }}
    >
      <DialogTitle>Uredi medij</DialogTitle>
      <DialogContent>
        <DataHandlerComponent
          loading={loading}
          error={Boolean(error)}
          skeletonHeight={60}
          skeletonNum={2}
          hasData={Boolean(data?.oneMedia)}
        >
          <>
            <Typography color="textSecondary">
              One stavke su važne za pristupačnost stranice
            </Typography>

            <TextField
              {...inputFields.title.inputProps}
              autoFocus
              margin="normal"
              fullWidth
            />
            <TextField
              {...inputFields.alt.inputProps}
              margin="normal"
              fullWidth
            />
          </>
        </DataHandlerComponent>
      </DialogContent>

      <DialogActions>
        <Button variant="text" color="primary" onClick={onClose}>
          Odustani
        </Button>
        <Button
          disabled={!didValuesChange(inputFieldNames)}
          variant="contained"
          color="primary"
          onClick={handleUpdateMedia}
        >
          Uredi
        </Button>
      </DialogActions>
      <LoadingBackdrop loading={loadingUpdateMediaMutation} />
    </Dialog>
  );
};
