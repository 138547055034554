import {
  DeleteOutlined as DeleteOutlinedIcon,
  EditOutlined as EditOutlinedIcon,
} from "@mui/icons-material";
import { Button, TableCell, TableRow } from "@mui/material";
import { FC } from "react";
import { NavLink } from "react-router-dom";
import { PublishedShowcase } from "../../other/publishedShowcase/PublishedShowcase";
import { useGlobalStyles } from "../../../styles/theme";
import { format } from "date-fns";
import { IPost } from "../../../apollo/queries";
// import { useStyles } from "./InteractiveTourItem.styles";

interface IProps {
  index: number;
  data: IPost;
  handleDelete: () => void;
}

export const PageItem: FC<IProps> = (props) => {
  const { id, title, created, published } = props.data;
  const { handleDelete, index } = props;

  // const { classes } = useStyles();
  const { classes: globalClasses } = useGlobalStyles();

  return (
    <TableRow>
      <TableCell>{index}</TableCell>
      <TableCell>
        <strong>{title || <i>{`Stranica bez naziva - ${id}`}</i>}</strong>
      </TableCell>
      <TableCell>{format(new Date(created), "dd.MM.yyyy. HH:mm'h'")}</TableCell>
      <TableCell>
        <PublishedShowcase published={published} />
      </TableCell>

      <TableCell>
        <div className={globalClasses.tableActionButtons}>
          <NavLink to={id} className={globalClasses.disableNavlinkDecoration}>
            <Button startIcon={<EditOutlinedIcon />} size="small">
              Uredi
            </Button>
          </NavLink>
          <Button
            startIcon={<DeleteOutlinedIcon />}
            onClick={handleDelete}
            size="small"
          >
            Obriši
          </Button>
        </div>
      </TableCell>
    </TableRow>
  );
};
