import { makeStyles } from "tss-react/mui";

export const useStyles = makeStyles()((theme) => ({
  uploadAreaIcon: {
    fontSize: 64,
    zIndex: 2,
  },
  uploadButton: {
    height: 164,
    display: "flex",
    flexDirection: "column",
    marginTop: theme.spacing(1),
    borderColor: theme.palette.grey[400],
    "&:active, &:focus": {
      borderColor: theme.palette.primary.main,
    },
    "&:hover": {
      borderColor: theme.palette.grey[800],
    },
  },
  uploadAreaText: {
    fontSize: 24,
  },
  mediaContainer: {
    display: "flex",
    flexWrap: "wrap",
    padding: theme.spacing(2),
    paddingBottom: theme.spacing(1),
    justifyContent: "space-between",
    border: "1px solid #ccc",
    borderTop: "none",
    borderRadius: theme.shape.borderRadius,
    // backgroundColor: theme.palette.secondary.lighter,
    maxHeight: 336,
    overflow: "auto",
  },
}));
