import type { DropdownMenuProps } from "@radix-ui/react-dropdown-menu";

import { ELEMENT_BLOCKQUOTE } from "@udecode/plate-block-quote";
import {
  focusEditor,
  insertEmptyElement,
  useEditorRef,
} from "@udecode/plate-common";
import { ELEMENT_H2, ELEMENT_H3 } from "@udecode/plate-heading";
import { ELEMENT_PARAGRAPH } from "@udecode/plate-paragraph";

import { Icons } from "../icons";

import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
  useOpenState,
} from "./dropdown-menu";
import { ToolbarButton } from "./toolbar";
import { ELEMENT_HR } from "@udecode/plate-horizontal-rule";
import { ELEMENT_IMAGE, insertMedia } from "@udecode/plate-media";
import {
  ELEMENT_LINK,
  insertLink,
  triggerFloatingLink,
} from "@udecode/plate-link";
import { Fragment } from "react/jsx-runtime";
import { MediaSelectorDialog } from "../../mediaSelectorContent/components";
import { useEffect, useState } from "react";
import { IMediaSelectDialogContext, useForm } from "../../../utils";
import { createTypedDynamicFormInputs } from "../../../utils";
import { useSnackbar } from "notistack";

const items = [
  {
    items: [
      {
        description: "Paragraf",
        icon: Icons.Paragraph,
        label: "Paragraf",
        value: ELEMENT_PARAGRAPH,
      },
      {
        description: "Naslov 2",
        icon: Icons.H2,
        label: "Naslov 2",
        value: ELEMENT_H2,
      },
      {
        description: "Naslov 3",
        icon: Icons.H3,
        label: "Naslov 3",
        value: ELEMENT_H3,
      },
      {
        description: "Citat",
        icon: Icons.Blockquote,
        label: "Citat",
        value: ELEMENT_BLOCKQUOTE,
      },
      {
        value: ELEMENT_HR,
        label: "Horizontalna linija",
        description: "Horizontalna linija (---)",
        icon: Icons.Hr,
      },
    ],
    label: "Obični blokovi",
  },
  {
    label: "Liste",
    items: [
      {
        value: "ul",
        label: "Neuređena lista",
        description: "Neuređena lista",
        icon: Icons.Ul,
      },
      {
        value: "ol",
        label: "Uređena lista",
        description: "Uređena lista",
        icon: Icons.Ol,
      },
    ],
  },
  {
    label: "Mediji",
    items: [
      {
        value: ELEMENT_IMAGE,
        label: "Slika",
        description: "Slika",
        icon: Icons.Image,
      },
      {
        value: "a2",
        label: "Dokument",
        description: "Dokument",
        icon: Icons.Document,
      },
      // {
      //   value: ELEMENT_MEDIA_EMBED,
      //   label: 'Embed',
      //   description: 'Embed',
      //   icon: Icons.embed,
      // },
    ],
  },
  {
    label: "Linkovi",
    items: [
      {
        value: ELEMENT_LINK,
        label: "Link",
        description: "Link",
        icon: Icons.link,
      },
    ],
  },
];

export const initialInputData = createTypedDynamicFormInputs({
  image: {
    inputProps: {
      name: "image",
      label: "Image",
      required: true,
    },
    rule: "media",
  },
  document: {
    inputProps: {
      name: "document",
      label: "Dokument",
      required: true,
    },
    rule: "media",
  },
});

export function InsertDropdownMenu(props: DropdownMenuProps) {
  const editor = useEditorRef();
  const openState = useOpenState();
  const { enqueueSnackbar } = useSnackbar();

  const { inputFields, resetFields } = useForm(initialInputData);

  const [mediaSelectDialogOpen, setMediaSelectDialogOpen] =
    useState<IMediaSelectDialogContext>({
      open: false,
      max: undefined,
      onChange: undefined,
      selectedMedia: [],
      type: undefined,
    });

  useEffect(() => {
    if (inputFields.image.values.unlocalised.media.length) {
      insertMedia(editor, {
        type: ELEMENT_IMAGE,
        getUrl: async () => {
          return await `${process.env.REACT_APP_BUCKET_ENDPOINT}${
            inputFields.image.values.unlocalised.media[0].bucketPath
          }?alt=${inputFields.image.values.unlocalised.media[0].alt || "x"}`;
        },
      });
      resetFields(["image"]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputFields.image.values.unlocalised.media]);

  useEffect(() => {
    if (inputFields.document.values.unlocalised.media.length) {
      insertLink(editor, {
        target: "_blank",
        text:
          `${inputFields.document.values.unlocalised.media[0].title} [[${inputFields.document.values.unlocalised.media[0].alt}]]` ||
          "",
        url: `${process.env.REACT_APP_BUCKET_ENDPOINT}${inputFields.document.values.unlocalised.media[0].bucketPath}`,
      });
      resetFields(["document"]);
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputFields.document.values.unlocalised.media]);

  const handleCloseMediaSelectDialog = () => {
    setMediaSelectDialogOpen((prevState) => ({
      ...prevState,
      open: false,
    }));
  };

  return (
    <>
      <DropdownMenu modal={false} {...openState} {...props}>
        <DropdownMenuTrigger asChild>
          <ToolbarButton isDropdown pressed={openState.open} tooltip="Insert">
            <Icons.Add />
          </ToolbarButton>
        </DropdownMenuTrigger>

        <DropdownMenuContent
          align="start"
          className="flex max-h-[500px] min-w-0 flex-col gap-0.5 overflow-y-auto"
        >
          {items.map(({ items: nestedItems, label }, index) => (
            <Fragment key={label}>
              {index !== 0 && <DropdownMenuSeparator />}

              <DropdownMenuLabel>{label}</DropdownMenuLabel>
              {nestedItems.map(
                ({ icon: Icon, label: itemLabel, value: type }) => (
                  <DropdownMenuItem
                    className="min-w-[180px]"
                    key={type}
                    onSelect={() => {
                      switch (type) {
                        // case ELEMENT_CODE_BLOCK: {
                        //   insertEmptyCodeBlock(editor);
                        //
                        //   break;
                        // }
                        case ELEMENT_IMAGE: {
                          setMediaSelectDialogOpen((prevState) => ({
                            ...prevState,
                            onChange: inputFields.image.inputProps.onChange,
                            type: "image",
                            open: true,
                          }));

                          // insertMedia(editor, { type: ELEMENT_IMAGE });

                          break;
                        }
                        // case ELEMENT_MEDIA_EMBED: {
                        //   await insertMedia(editor, {
                        //     type: ELEMENT_MEDIA_EMBED,
                        //   });
                        //
                        //   break;
                        // }
                        // case 'ul':
                        // case 'ol': {
                        //   insertEmptyElement(editor, ELEMENT_PARAGRAPH, {
                        //     select: true,
                        //     nextBlock: true,
                        //   });

                        //   if (settingsStore.get.checkedId(KEY_LIST_STYLE_TYPE)) {
                        //     toggleIndentList(editor, {
                        //       listStyleType: type === 'ul' ? 'disc' : 'decimal',
                        //     });
                        //   } else if (settingsStore.get.checkedId('list')) {
                        //     toggleList(editor, { type });
                        //   }

                        //   break;
                        // }
                        // case ELEMENT_TABLE: {
                        //   insertTable(editor);
                        //
                        //   break;
                        // }
                        case "a2": {
                          setMediaSelectDialogOpen((prevState) => ({
                            ...prevState,
                            onChange: inputFields.document.inputProps.onChange,
                            type: "document",
                            open: true,
                          }));
                          // triggerFloatingLink(editor, { focused: true });

                          break;
                        }
                        case ELEMENT_LINK: {
                          triggerFloatingLink(editor, { focused: true });

                          break;
                        }
                        default: {
                          insertEmptyElement(editor, type, {
                            nextBlock: true,
                            select: true,
                          });
                        }
                      }

                      focusEditor(editor);
                    }}
                  >
                    <Icon className="mr-2 size-5" />
                    {itemLabel}
                  </DropdownMenuItem>
                )
              )}
            </Fragment>
          ))}
        </DropdownMenuContent>
      </DropdownMenu>
      <MediaSelectorDialog
        onClose={handleCloseMediaSelectDialog}
        dialogContext={mediaSelectDialogOpen}
      />
    </>
  );
}
