import { makeStyles } from "tss-react/mui";

export const useStyles = makeStyles()((theme) => ({
  marginLeft: {
    marginLeft: theme.spacing(1),
  },
  slugInput: {
    flexBasis: "45%",
  },
  inputs: {
    alignItems: "flex-start",
  },
  slugRender: {
    color: theme.palette.primary.main,
  },
  marginUp: {
    marginTop: theme.spacing(2),
  },
  marginDown: {
    marginBottom: theme.spacing(2),
  },
  breakWord: {
    wordWrap: "break-word",
    wordBreak: "break-word",
    lineBreak: "strict",
    fontSize: "0.5rem",
  },
}));
