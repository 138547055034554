import { createContext, Dispatch, SetStateAction } from "react";
import { ICurrentUser } from "../apollo/queries";

export interface IContextProvider {
  user: ICurrentUser | undefined | null;
  setUser: Dispatch<SetStateAction<ICurrentUser | undefined | null>>;
  languageFlags: any;
}

export const ContextProvider = createContext<IContextProvider>({
  user: undefined,
  languageFlags: [],
  setUser: () => {},
});
