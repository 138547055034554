import { useSnackbar } from "notistack";
import { useGlobalStyles } from "../../../styles/theme";
import { redirect, useNavigate, useParams } from "react-router-dom";
import {
  DataHandlerComponent,
  LoadingBackdrop,
  PageLayout,
  ReadyToPublish,
} from "../../../components";
import {
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  IconButton,
  InputAdornment,
  MenuItem,
  Paper,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import {
  ERouterPaths,
  IMediaSelectDialogContext,
  INPUT_POST_SLUG_MAX_LENGTH,
  INPUT_POST_TITLE_MAX_LENGTH,
  MSG_GENERIC_ERROR,
  MSG_UNSAVED_CHANGES,
  joinClassNames,
  useForm,
} from "../../../utils";
import { initialInputData } from "./PostPage.inputs";
import { ChangeEvent, useEffect, useRef, useState } from "react";
import slugify from "slugify";
import { InfoOutlined, RestartAltOutlined } from "@mui/icons-material";
import { useStyles } from "./PostPage.styles";
import {
  MediaSelectButton,
  MediaSelectorDialog,
} from "../../../components/mediaSelectorContent/components";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import {
  CREATE_POST,
  ICreatePostData,
  ICreatePostVars,
  IUpdatePostData,
  IUpdatePostPublishedData,
  IUpdatePostPublishedVars,
  IUpdatePostVars,
  UPDATE_POST,
  UPDATE_POST_PUBLISHED,
} from "../../../apollo/mutations";
import {
  ALL_POSTS,
  GET_SLUG_STRUCTURE,
  IPost,
  IPostData,
  IPostVars,
  IPostsData,
  ISlugStructureData,
  ISlugStructureVars,
  ONE_POST,
} from "../../../apollo/queries";
import { PlateRteEditor } from "../../../components/plateRte/PlateRte";
import { PlateEditor } from "@udecode/plate-common";
import { rteSerialize } from "../../../utils/rteUtils";

export const PostPage = () => {
  const { postId } = useParams();
  const { classes: globalClasses } = useGlobalStyles();
  const { classes, cx } = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const editorRef = useRef<PlateEditor | null>(null);
  // const editorRef = useEditorRef();

  const {
    inputFields,
    validateForm,
    inputFieldNames,
    handleDataToVar,
    getFormValuesFromFetchedData,
    didValuesChange,
    resetFields,
    setInputField,
    setInputFields,
  } = useForm<keyof typeof initialInputData>(initialInputData);

  const [queryOnePost, { loading, data, error }] = useLazyQuery<
    IPostData,
    IPostVars
  >(ONE_POST);

  const {
    loading: loadingSlugStructure,
    data: dataSlugStructure,
    error: errorSlugStructure,
    refetch: refetchSlugStructure,
  } = useQuery<ISlugStructureData, ISlugStructureVars>(GET_SLUG_STRUCTURE, {
    fetchPolicy: "network-only",
    variables: {
      getAll: true,
    },
  });

  const [createPostMutation, { loading: loadingCreatePostMutation }] =
    useMutation<ICreatePostData, ICreatePostVars>(CREATE_POST, {
      onCompleted: (data) => {
        enqueueSnackbar(
          `Nova stranica dodana: ${
            data.createPost.title || "Stranica bez naslova"
          }`,
          {
            variant: "success",
          }
        );
        // navigate(`/${ERouterPaths.POSTS}`);
        resetFields(inputFieldNames, true);
        navigate(`/${ERouterPaths.POSTS}/${data.createPost.id}`, {
          replace: true,
        });
      },
      onError: (error) => {
        enqueueSnackbar(error.message, {
          variant: "error",
        });
      },
      //TODO: Check if no classicTours
      update: (cache, { data }) => {
        const existingListData: IPostsData | null = cache.readQuery({
          query: ALL_POSTS,
          variables: {
            query: "",
          },
        });
        if (data?.createPost) {
          const newPostData: IPost = {
            ...data.createPost,
          };
          cache.writeQuery({
            query: ALL_POSTS,
            variables: {
              query: "",
            },
            data: {
              allPosts: existingListData?.allPosts
                ? [newPostData, ...existingListData.allPosts]
                : [newPostData],
            },
          });
        }
      },
    });

  const [updatePostMutation, { loading: loadingUpdatePostMutation }] =
    useMutation<IUpdatePostData, IUpdatePostVars>(UPDATE_POST, {
      onCompleted: (data) => {
        enqueueSnackbar(
          `Stranica je ažurirana: ${
            data.updatePost.title || "Stranica bez naslova"
          }`,
          {
            variant: "success",
          }
        );
        refetchSlugStructure();
        resetFields(inputFieldNames, true);
      },
      onError: (error) => {
        enqueueSnackbar(error.message, {
          variant: "error",
        });
      },
    });

  useEffect(() => {
    //TODO: CHECK
    // if (fetchDataDone.current) {
    //   return;
    // }
    if (!data?.onePost) {
      return;
    }

    getFormValuesFromFetchedData(data.onePost, [
      {
        fromDataProperty: "header.id",
        toFormProperty: "header",
      },
      {
        fromDataProperty: "title",
        toFormProperty: "title",
      },
      {
        fromDataProperty: "slug",
        toFormProperty: "slug",
      },
      {
        fromDataProperty: "parentId",
        toFormProperty: "parent",
      },
      {
        fromDataProperty: "showNestedPosts",
        toFormProperty: "showNestedPosts",
      },
      {
        fromDataProperty: "featured",
        toFormProperty: "featured",
      },
    ]);

    // setInputField("content", parsedRte);
    // editor.children = parsedRte;

    if (editorRef.current && data.onePost.contentRaw) {
      const parsedRte = JSON.parse(data.onePost.contentRaw);

      const point = { path: [0, 0], offset: 0 };
      editorRef.current.selection = { anchor: point, focus: point };
      editorRef.current.history = { redos: [], undos: [] };
      editorRef.current.children = JSON.parse(data.onePost.contentRaw);

      setInputFields((prev) => ({
        ...prev,
        content: {
          ...prev.content,
          inputProps: {
            ...prev.content.inputProps,
            value: editorRef?.current?.children,
          },
          initialValues: {
            ...prev.content.initialValues,
            unlocalised: {
              hasChanged: false,
              media: [],
              value: data.onePost?.contentRaw || "",
              rteValue: parsedRte,
            },
          },
          values: {
            ...prev.content.values,
            unlocalised: {
              ...prev.content.values.unlocalised,
              value: data.onePost?.contentRaw || "",
              rteValue: parsedRte,
            },
          },
        },
      }));
    }

    // fetchDataDone.current = true;

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.onePost]);

  const handleCreatePost = () => {
    if (!validateForm(inputFieldNames, false)) {
      return;
    }

    createPostMutation({
      variables: {
        data: {
          title: handleDataToVar("title", "string", false),
          content: handleDataToVar("content", "string", false)
            ? //@ts-ignore
              rteSerialize(editorRef.current?.children)
            : handleDataToVar("content", "string", false),
          contentRaw: handleDataToVar("content", "string", false)
            ? JSON.stringify(editorRef.current?.children)
            : handleDataToVar("content", "string", false),
          headerId: handleDataToVar("header", "number", false),
          slug: handleDataToVar("slug", "string", false),
          parentId: handleDataToVar("parent", "number", false),
          showNestedPosts: handleDataToVar("showNestedPosts", "boolean", false),
          featured: handleDataToVar("featured", "boolean", false),
        },
      },
    });
  };

  const [mediaSelectDialogOpen, setMediaSelectDialogOpen] =
    useState<IMediaSelectDialogContext>({
      open: false,
      max: undefined,
      onChange: undefined,
      selectedMedia: [],
      type: undefined,
    });

  const handleCloseMediaSelectDialog = () => {
    setMediaSelectDialogOpen((prevState) => ({
      ...prevState,
      open: false,
    }));
  };

  const handleRteOnBlur = () => {
    if (editorRef.current) {
      setInputField("content", editorRef.current.children);
    }
  };

  const handleSlugify = () => {
    if (!inputFields.title.inputProps.value) {
      enqueueSnackbar(
        "Naslov je potreban da se od njega automatski napravi slug!",
        {
          variant: "error",
        }
      );
      return;
    }

    const slug = slugify(inputFields.title.inputProps.value, {
      lower: true,
      strict: true,
      locale: "hr",
      trim: true,
    });

    enqueueSnackbar(`Slug kreiran: ${slug}`, {
      variant: "success",
    });
    setInputField("slug", slug);
  };

  useEffect(() => {
    if (postId) {
      queryOnePost({ variables: { id: +postId } });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [postId]);

  const handleUpdatePost = () => {
    if (!validateForm(inputFieldNames, data?.onePost?.published)) {
      return null;
    }

    if (postId) {
      updatePostMutation({
        variables: {
          id: +postId,
          data: {
            headerId: handleDataToVar("header", "number"),
            title: handleDataToVar("title", "string"),
            content: handleDataToVar("content", "string")
              ? //@ts-ignore
                rteSerialize(editorRef.current?.children)
              : handleDataToVar("content", "string"),
            contentRaw: handleDataToVar("content", "string")
              ? JSON.stringify(editorRef.current?.children)
              : handleDataToVar("content", "string"),
            slug: handleDataToVar("slug", "string"),
            parentId: handleDataToVar("parent", "number"),
            showNestedPosts: handleDataToVar("showNestedPosts", "boolean"),
            featured: handleDataToVar("featured", "boolean"),
          },
        },
      });
    } else {
      enqueueSnackbar("ID not found!", {
        variant: "error",
      });
    }
  };

  const [
    updatePostPublishedMutation,
    { loading: loadingUpdatePostPublishedMutation },
  ] = useMutation<IUpdatePostPublishedData, IUpdatePostPublishedVars>(
    UPDATE_POST_PUBLISHED,
    {
      onCompleted: (data) => {
        if (!data.updatePostPublished?.published) {
          enqueueSnackbar("Stranica je skrivena javnosti!", {
            variant: "info",
          });
        } else {
          enqueueSnackbar("Stranica je objavljena i vidljiva je javnosti.", {
            variant: "success",
          });
        }
      },
      onError: (error) => {
        enqueueSnackbar(error.message, {
          variant: "error",
        });
      },
    }
  );

  const handleUpdateTourPublished = () => {
    if (!data?.onePost?.id || !postId) {
      enqueueSnackbar(MSG_GENERIC_ERROR, {
        variant: "error",
      });
      return;
    }

    if (didValuesChange(inputFieldNames, true) && !data.onePost.published) {
      enqueueSnackbar(MSG_UNSAVED_CHANGES, { variant: "warning" });
      return;
    }
    if (validateForm(inputFieldNames, !data.onePost.published, true)) {
      updatePostPublishedMutation({
        variables: {
          id: +postId,
          publish: data.onePost.published ? false : true,
        },
      });
    }
  };

  const handleShowNestedPostsCheckboxChange = (
    event: ChangeEvent<HTMLInputElement>
  ) => {
    setInputField("showNestedPosts", event.target.checked ? "TRUE" : "");
  };

  const handleFeaturedCheckboxChange = (
    event: ChangeEvent<HTMLInputElement>
  ) => {
    setInputField("featured", event.target.checked ? "TRUE" : "");
  };

  return (
    <PageLayout displayFlex>
      <Paper className={globalClasses.paperRoot}>
        <div className={globalClasses.paperTitle}>
          <div className={globalClasses.justifySpaceBetween}>
            <Typography variant="h6">
              {postId ? "Uredi stranicu" : "Dodaj novu stranicu"}
            </Typography>
          </div>
        </div>
        <div
          className={joinClassNames([
            globalClasses.paperContainer,
            globalClasses.flex,
          ])}
        >
          <DataHandlerComponent
            error={Boolean(error)}
            hasData={postId ? Boolean(data?.onePost?.id) : true}
            loading={loading}
          >
            <TextField
              margin="normal"
              {...inputFields.title.inputProps}
              autoFocus
              fullWidth
              inputProps={{
                maxLength: INPUT_POST_TITLE_MAX_LENGTH,
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <Typography variant="caption">{`${inputFields.title.inputProps.value.length}/${INPUT_POST_TITLE_MAX_LENGTH}`}</Typography>
                  </InputAdornment>
                ),
              }}
            />

            {/* 
            <RichTextEditor
              // className={styles.rteInputField}
              editor={editor}
              // readOnly={!edit}
              label={inputFields.content.inputProps.label}
              // onChange={() => {}}
              // onChange={handleRteOnChange}
              // value={[] as Descendant[]}
              onBlur={handleRteOnBlur}
            /> */}

            <div
              className={joinClassNames([
                globalClasses.justifySpaceBetween,
                classes.inputs,
              ])}
            >
              <TextField
                margin="normal"
                disabled={data?.onePost?.locked}
                {...inputFields.slug.inputProps}
                inputProps={{
                  maxLength: INPUT_POST_SLUG_MAX_LENGTH,
                }}
                className={classes.slugInput}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Typography variant="caption">{`${inputFields.slug.inputProps.value.length}/${INPUT_POST_SLUG_MAX_LENGTH}`}</Typography>
                      <Tooltip title="Automatski izradi slug od naslova stranice">
                        <IconButton
                          disabled={data?.onePost?.locked}
                          onClick={handleSlugify}
                          color="primary"
                          className={cx(classes.marginLeft)}
                        >
                          <RestartAltOutlined />
                        </IconButton>
                      </Tooltip>
                      <Tooltip
                        enterDelay={0}
                        title={
                          <>
                            <p>
                              <b>Informacije o slug-u:</b>
                            </p>
                            <ul>
                              <li>
                                Slug se može sastojati od malih slova, brojeva i
                                "-" znaka
                              </li>
                              <li>Idealno je da slug izgleda slično naslovu</li>
                              <li>
                                Slug mora biti unikatan. Druge stranice ne smiju
                                imati isti slug u zajedničkoj kategoriji.
                              </li>
                              <li>
                                Nakon objave stranice, slug nije pametno za
                                mijenjati jer može doći do greške ako se pokuša
                                otvoriti ista stranica sa starim linkom.
                              </li>
                              <li>
                                Npr: 'https://primjer.com/blog/naslov-clanka',
                                slug je 'naslov-clanka'
                              </li>
                            </ul>
                          </>
                        }
                      >
                        <InfoOutlined
                          className={cx(classes.marginLeft)}
                          color="info"
                        />
                      </Tooltip>
                    </InputAdornment>
                  ),
                }}
              />

              <TextField
                {...inputFields.parent.inputProps}
                margin="normal"
                disabled={data?.onePost?.locked}
                className={globalClasses.inputSmallFlex}
                select
                multiline
                SelectProps={{
                  renderValue(value) {
                    if (!value) {
                      return "/";
                    }
                    return (
                      <>
                        {dataSlugStructure?.getSlugStructure
                          ?.find((x) => x.id === value)
                          ?.slug.join("/")}
                        <p>
                          <span className={classes.slugRender}>
                            <i>{`/[${inputFields.slug.values.unlocalised.value}]`}</i>
                          </span>
                        </p>
                      </>
                    );
                  },
                }}
              >
                <MenuItem value="">-- Bez kategorije --</MenuItem>
                {dataSlugStructure?.getSlugStructure?.length ? (
                  dataSlugStructure.getSlugStructure.map((item) => (
                    <MenuItem value={item.id} key={item.slug.join("/")}>
                      {item.slug.join("/")}
                    </MenuItem>
                  ))
                ) : loadingSlugStructure ? (
                  <MenuItem disabled>Loading...</MenuItem>
                ) : null}
              </TextField>
            </div>

            <MediaSelectButton
              setMediaSelectDialogOpen={setMediaSelectDialogOpen}
              max={1}
              selectType="image"
              className={classes.marginDown}
              {...inputFields.header.inputProps}
            />

            <PlateRteEditor
              editorRef={editorRef}
              onBlur={handleRteOnBlur}
              setInputFields={setInputFields}
              initialValue={data?.onePost?.contentRaw}
            />

            <FormGroup
              className={`${globalClasses.fullWidth} ${classes.marginUp}`}
            >
              <Tooltip title={inputFields.showNestedPosts.mainHelperText}>
                <FormControlLabel
                  control={
                    <Checkbox
                      disabled={
                        data?.onePost?.locked &&
                        data?.onePost?.slug === "obavijesti"
                      }
                      checked={Boolean(
                        inputFields.showNestedPosts.inputProps.value
                      )}
                      onChange={handleShowNestedPostsCheckboxChange}
                    />
                  }
                  label={inputFields.showNestedPosts.inputProps.label}
                />
              </Tooltip>
              <Tooltip title={inputFields.featured.mainHelperText}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={Boolean(inputFields.featured.inputProps.value)}
                      onChange={handleFeaturedCheckboxChange}
                    />
                  }
                  label={inputFields.featured.inputProps.label}
                />
              </Tooltip>
            </FormGroup>
          </DataHandlerComponent>
        </div>
        <div className={globalClasses.paperButtons}>
          <div className={globalClasses.paperButtonsLeft}>
            {postId && data?.onePost ? (
              <ReadyToPublish
                handlePublish={handleUpdateTourPublished}
                published={data.onePost.published}
              />
            ) : null}
          </div>
          <div className={globalClasses.paperButtonsRight}>
            {!postId ? (
              <Button onClick={handleCreatePost} variant="contained">
                Dodaj
              </Button>
            ) : (
              <Button
                onClick={handleUpdatePost}
                variant="contained"
                disabled={
                  !didValuesChange(inputFieldNames, data?.onePost?.published)
                }
              >
                Ažuriraj
              </Button>
            )}
          </div>
        </div>
      </Paper>
      <MediaSelectorDialog
        dialogContext={mediaSelectDialogOpen}
        onClose={handleCloseMediaSelectDialog}
      />
      <LoadingBackdrop
        loading={
          loadingUpdatePostMutation ||
          loadingCreatePostMutation ||
          loadingUpdatePostPublishedMutation
        }
      />
    </PageLayout>
  );
};
