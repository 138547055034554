import { createTypedDynamicFormInputs } from "../../../utils";

export const initialInputData = createTypedDynamicFormInputs({
  name: {
    inputProps: {
      label: "Ime",
      name: "name",
      placeholder: "John Doe",
      required: true,
    },
  },
  email: {
    rule: "email",
    inputProps: {
      label: "Email",
      name: "email",
      placeholder: "admin@example.com",
      required: true,
    },
  },
});
