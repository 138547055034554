import { createTypedDynamicFormInputs } from "../../../utils";

export const initialInputData = createTypedDynamicFormInputs({
  currentPassword: {
    inputProps: {
      label: "Trenutna lozinka",
      type: "password",
      required: true,
      name: "currentPassword",
    },
  },
  newPassword: {
    inputProps: {
      required: true,
      label: "Nova lozinka",
      type: "password",
      name: "newPassword",
    },
  },
});
