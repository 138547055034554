import { gql } from "@apollo/client";
import { ICurrentUser } from "../../queries";
import { F_PERSON } from "../../fragments";

export interface ILoginData {
  login: ICurrentUser;
}

export interface ILoginVars {
  data: {
    email: string;
    password: string;
    remember: boolean;
  };
}

export const LOGIN = gql`
  ${F_PERSON}
  mutation login($data: LoginInput!) {
    login(data: $data) {
      ...FPerson
    }
  }
`;
