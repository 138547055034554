import { FC, useContext } from "react";
import {
  Navigate,
  Outlet,
  Route,
  RouterProvider,
  createBrowserRouter,
  createRoutesFromElements,
} from "react-router-dom";
import {
  AdministratorsPage,
  ForgotPasswordPage,
  JoinPage,
  LoginPage,
  NotFoundPage,
  PostPage,
  ResetPasswordPage,
  PostListPage,
  MediaPage,
  // OpeningHoursPage,
} from "./";
import { ContextProvider, ERouterPaths } from "../utils";
import { Header, Navbar } from "../components";
import {} from "./private/postListPage/PostListPage";

interface IProps {
  children?: React.ReactNode;
}

const PrivateOutlet: FC<IProps> = (props) => {
  const { children } = props;
  const { user } = useContext(ContextProvider);

  if (user && user.id) {
    return (
      <>
        {children}
        <Outlet />
      </>
    );
  }

  return <Navigate to="/prijava" replace />;
};

const PublicOutlet: FC<IProps> = ({ children }) => {
  const { user } = useContext(ContextProvider);

  return !user ? (
    <>
      {children}
      <Outlet />
    </>
  ) : (
    <Navigate to="/" replace />
  );
};

export const MainRouter = () => {
  const { user } = useContext(ContextProvider);

  const router = createBrowserRouter(
    createRoutesFromElements(
      <>
        <Route
          path="/"
          element={
            <>
              <Header />
              {user ? <Navbar /> : null}
              <Outlet />
            </>
          }
        >
          <Route
            index
            element={
              <PrivateOutlet>
                <Navigate to={ERouterPaths.POSTS} />
              </PrivateOutlet>
            }
          />
          <Route
            path={ERouterPaths.POSTS}
            element={
              <PrivateOutlet>
                <PostListPage />
              </PrivateOutlet>
            }
          />
          <Route
            path={`${ERouterPaths.POSTS}/new`}
            element={
              <PrivateOutlet>
                <PostPage />
              </PrivateOutlet>
            }
          />
          <Route
            path={`${ERouterPaths.POSTS}/:postId`}
            element={
              <PrivateOutlet>
                <PostPage />
              </PrivateOutlet>
            }
          />
          <Route
            path={ERouterPaths.MEDIA}
            element={
              <PrivateOutlet>
                <MediaPage />
              </PrivateOutlet>
            }
          />
          {/* <Route
            path={ERouterPaths.OPENING_HOURS}
            element={
              <PrivateOutlet>
                <OpeningHoursPage />
              </PrivateOutlet>
            }
          /> */}
          <Route
            path={ERouterPaths.ADMINISTRATORS}
            element={
              <PrivateOutlet>
                <AdministratorsPage />
              </PrivateOutlet>
            }
          />
          <Route
            path={ERouterPaths.LOGIN}
            element={
              <PublicOutlet>
                <LoginPage />
              </PublicOutlet>
            }
          />
          <Route
            path={ERouterPaths.FORGOT_PASSWORD}
            element={<ForgotPasswordPage />}
          />
          <Route path="*" element={<NotFoundPage />} />
          <Route
            path={`${ERouterPaths.RESET_PASSWORD}/:token`}
            element={<ResetPasswordPage />}
          />

          <Route path={ERouterPaths.JOIN} element={<JoinPage />} />
        </Route>
      </>
    )
  );

  return <RouterProvider router={router} />;
};
