import { gql } from "@apollo/client";
import { F_PERSON } from "../../fragments";

export interface IAdmin {
  id: string;
  name: string;
  email: string;
}

export interface IAdminData {
  oneAdministrator: IAdmin;
}

export interface IAdminsData {
  allAdministrators: IAdmin[];
}

export interface IAdminVars {
  id: number;
}

export const ONE_ADMIN = gql`
  ${F_PERSON}
  query oneAdministrator($id: ID!) {
    oneAdministrator(id: $id) {
      ...FPerson
    }
  }
`;

export const ALL_ADMINISTRATORS = gql`
  ${F_PERSON}
  query allAdministrators {
    allAdministrators {
      ...FPerson
    }
  }
`;
