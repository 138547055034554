import { TextFieldProps } from "@mui/material";
import { Value } from "@udecode/plate-common";
// import { ILanguageFlag, IMedia } from "../apollo/queries";
import deepCopy from "deepcopy";
import { IMedia } from "../apollo/queries";

//*Initial InputFields
export interface IDynamicFormInitialInputData {
  [key: string]: IDynamicFormInitialInput;
}

export interface IDynamicFormInitialInput {
  isCheckbox?: boolean; //TODO: Move to rule
  readonly rule?: "email" | "slateRte" | "media";
  localise?: boolean;
  regexPattern?: RegExp;
  mainErrorText?: string;
  mainHelperText?: string;
  strictRequired?: boolean;
  mainLocaleRequiredOnly?: boolean;
  inputProps: IInputProps;
}

export function createTypedDynamicFormInputs<
  T extends { [name: string]: IDynamicFormInitialInput }
>(input: T): Record<keyof T, IDynamicFormInitialInput> {
  return input;
}

export interface IDynamicFormInputData {
  [key: string]: IDynamicFormInput;
}

export interface IDynamicFormInput extends IDynamicFormInitialInput {
  values: ILocalisedValues;
  initialValues: ILocalisedInitialValues;
}

//*Input Field props
export interface IInputPropsData {
  inputProps: IInputProps;
}

export interface IInputProps {
  value?: any;
  label: string;
  name: string;
  type?: TextFieldProps["type"];
  required?: boolean;
  error?: boolean;
  helperText?: string;
  disabled?: boolean;
  placeholder?: string;
  onChange?: (event: any) => void;
  onBlur?: (event: any) => void;
  InputProps?: any;
  classes?: any;
}

//TODO: Fix below
export interface ILocalisedValues {
  unlocalised: ILocalisedValue;
  [key: string]: ILocalisedValue;
}

export interface ILocalisedValue {
  value: string;
  rteValue?: Value | undefined;
  media: IMedia[];
}

export interface ILocalisedInitialValue extends ILocalisedValue {
  hasChanged: boolean;
  // shouldCheck: boolean;
}

export interface ILocalisedInitialValues {
  unlocalised: ILocalisedInitialValue;
  [key: string]: ILocalisedInitialValue;
}

// export type TValueToFormOptions = {
//   fromDataProperty: string;
//   //TODO: Link to key types
//   toFormProperty: T;
// }[];

// export const getFormValuesFromFetchedData = <
//   T extends string | number | symbol
// >(
//   data: { [key: string]: any },
//   valueToFormOptions: TValueToFormOptions,
//   inputFields: IDynamicFormInputData,
//   currentLocale: string = "unlocalised"
// ) => {
//   const inputFieldsCopy = deepCopy(inputFields);

//   valueToFormOptions.forEach((item) => {
//     item.fromDataProperty.split(".").reduce((obj, property, i) => {
//       // console.log(
//       //   "Get [",
//       //   i,
//       //   "] by property: -",
//       //   property,
//       //   "-. Current Object: ",
//       //   obj
//       // );
//       const isMediaRule = inputFieldsCopy[item.toFormProperty].rule === "media";
//       const returnValueProperty = isMediaRule ? "media" : "value";

//       if (Array.isArray(obj) && obj.length) {
//         // console.log("-InArray object: ", obj);

//         const arrayObject = obj.map((element: { [key: string]: any }) => {
//           const localeOrNot = inputFieldsCopy[item.toFormProperty].localise
//             ? element.languageFlag?.isoLanguageCode
//             : "unlocalised";

//           // console.log("-InArray object: ", element);
//           // console.log(localeOrNot);
//           // console.log("--InArray value element: ", element);

//           if (element[property]) {
//             // console.log("--InArray value: ", element[property]);

//             inputFieldsCopy[item.toFormProperty].values[localeOrNot] = {
//               // rteValue:
//               //   inputFieldsCopy[item.toFormProperty].rules === "slateRte"
//               //     ? parseRteValue(element[property])
//               //     : undefined,
//               value:
//                 inputFieldsCopy[item.toFormProperty].rule === "media" &&
//                 Array.isArray(element[property]) &&
//                 element[property].length
//                   ? element[property]
//                       .map((item: IMediaWithoutCount) => item.id)
//                       .join(",")
//                   : element[property],
//               media:
//                 inputFieldsCopy[item.toFormProperty].rule === "media"
//                   ? Array.isArray(element[property])
//                     ? element[property].map((item: IMedia) => {
//                         return {
//                           extension: item.extension,
//                           mimeType: item.mimeType,
//                           fileName: item.fileName,
//                           byteSize: item.byteSize,
//                           id: item.id,
//                           bucketPath: item.bucketPath,
//                         };
//                       })
//                     : [
//                         {
//                           extension: element[property].extension,
//                           mimeType: element[property].mimeType,
//                           fileName: element[property].fileName,
//                           byteSize: element[property].byteSize,
//                           id: element[property].id,
//                           bucketPath: element[property].bucketPath,
//                         },
//                       ]
//                   : undefined,
//             };
//             inputFieldsCopy[item.toFormProperty].inputProps.value =
//               inputFieldsCopy[item.toFormProperty].values[
//                 localeOrNot !== "unlocalised" ? currentLocale : "unlocalised"
//               ][returnValueProperty];
//             return element;
//             // } else {
//             //   console.log("NOT LOCALISED ", element[property]);
//             //   inputFieldsCopy[item.toFormProperty].value = item.isRte
//             //     ? parseRteValue((element as any)[property])
//             //     : (element as any)[property];
//             //   return (element as any)[property];
//           }
//           return element;
//         });

//         if (arrayObject.every((i: any) => typeof i === "string")) {
//           // console.log("Merge array into one string!", arrayObject.toString());
//           inputFieldsCopy[item.toFormProperty].values.unlocalised.value =
//             arrayObject.toString();
//           inputFieldsCopy[item.toFormProperty].inputProps.value =
//             inputFieldsCopy[item.toFormProperty].values.unlocalised.value;
//           return arrayObject.toString();
//         } else return arrayObject;
//       } else {
//         const localeOrNot = inputFieldsCopy[item.toFormProperty].localise
//           ? obj?.languageFlag?.isoLanguageCode
//           : "unlocalised";

//         console.log("LOCALEORNOT: ", localeOrNot, item.toFormProperty);
//         console.log("-InObj Object: ", obj);
//         // console.log("--InObj value: ", obj?.[property], item.toFormProperty);
//         inputFieldsCopy[item.toFormProperty].values[localeOrNot] = {
//           // rteValue:
//           //   inputFieldsCopy[item.toFormProperty].rules === "slateRte"
//           //     ? obj?.[property]
//           //       ? parseRteValue(obj[property])
//           //       : undefined
//           //     : undefined,
//           value:
//             obj?.[property] || obj?.[property] === 0
//               ? obj[property] === typeof "number"
//                 ? obj[property].toString()
//                 : inputFieldsCopy[item.toFormProperty].rule === "media" &&
//                   Array.isArray(obj[property]) &&
//                   obj[property].length
//                 ? obj[property]
//                     .map((item: IMediaWithoutCount) => item.id)
//                     .join(",")
//                 : Array.isArray(obj[property])
//                 ? obj[property][0].id
//                 : typeof obj[property] === "object" && obj[property] !== null
//                 ? obj[property].id
//                 : inputFieldsCopy[item.toFormProperty].isCheckbox
//                 ? obj[property]
//                   ? obj[property].toString()
//                   : ""
//                 : obj[property]
//               : "",
//           media:
//             inputFieldsCopy[item.toFormProperty].rule === "media" &&
//             obj?.[property]
//               ? Array.isArray(obj[property])
//                 ? obj[property].map((item: IMediaWithoutCount) => {
//                     return {
//                       id: item.id,
//                       extension: item.extension,
//                       mimeType: item.mimeType,
//                       fileName: item.fileName,
//                       byteSize: item.byteSize,
//                       bucketPath: item.bucketPath,
//                     } as IMediaWithoutCount;
//                   })
//                 : ([
//                     {
//                       id: obj[property].id,
//                       extension: obj[property].extension,
//                       mimeType: obj[property].mimeType,
//                       fileName: obj[property].fileName,
//                       byteSize: obj[property].byteSize,
//                       bucketPath: obj[property].bucketPath,
//                     },
//                   ] as IMediaWithoutCount[])
//               : ([] as IMediaWithoutCount[]),
//         };
//         inputFieldsCopy[item.toFormProperty].inputProps.value =
//           inputFieldsCopy[item.toFormProperty].values[
//             localeOrNot !== "unlocalised" ? currentLocale : "unlocalised"
//           ][returnValueProperty];
//         // console.log("IFC: ", inputFieldsCopy);
//         return obj?.[property];
//       }

//       // return obj as any;
//     }, data);
//     // console.log("DV: ", deepValue);

//     // Object.keys(inputFieldsCopy[item.toFormProperty].values).forEach((key) => {
//     //   inputFieldsCopy[item.toFormProperty].initialValues[key] = {
//     //     ...inputFieldsCopy[item.toFormProperty].values[key],
//     //     hasChanged: false,
//     //     shouldCheck: true,
//     //   };
//     // });
//   });
//   return inputFieldsCopy as Record<T, IDynamicFormInput>;
// };

export const localizeInputValues = <T extends string | number | symbol>(
  inputFields: IDynamicFormInitialInputData,
  flagsData: any[]
): Record<T, IDynamicFormInput> => {
  const newInputFields = deepCopy(inputFields);
  // console.log("NEW", newInputFields);

  Object.entries(newInputFields).forEach(([key, property]) => {
    const defaultValue: ILocalisedValue = {
      value: "",
      rteValue: [
        {
          type: "paragraph",
          children: [{ text: "", italic: false }],
        },
      ],
      media: [],
    };
    const defaultInitialValue: ILocalisedInitialValue = {
      ...defaultValue,
      hasChanged: false,
      // shouldCheck: false,
    };
    if (property.localise) {
      const localeInputField = flagsData.reduce(
        (obj: ILocalisedValues, item: any) => {
          // eslint-disable-next-line no-sequences
          return (obj[item.isoLanguageCode] = { ...defaultValue }), obj;
        },
        {
          unlocalised: { ...defaultValue },
        } as ILocalisedValues
      );
      const localeInitialInputField = flagsData.reduce(
        (obj: ILocalisedValues, item: any) => {
          // eslint-disable-next-line no-sequences
          return (obj[item.isoLanguageCode] = { ...defaultInitialValue }), obj;
        },
        {
          unlocalised: { ...defaultInitialValue },
        } as ILocalisedValues
      );
      newInputFields[key] = {
        ...newInputFields[key],
        //@ts-ignore
        values: {
          ...localeInputField,
        },
        initialValues: {
          ...localeInitialInputField,
        },
      };
    } else {
      //@ts-ignore
      newInputFields[key].values = {
        unlocalised: { ...defaultValue },
      };
      //@ts-ignore
      newInputFields[key].initialValues = {
        unlocalised: { ...defaultInitialValue },
      };
    }
  });

  return newInputFields as Record<T, IDynamicFormInput>;
};
