import { gql } from "@apollo/client";

export interface ICreateInvitation {
  id: string;
  email: string;

  created: string;
  expired: string | null;
}

export interface ICreateInvitationData {
  inviteAdmin: ICreateInvitation;
}
export interface ICreateInvitationVars {
  data: {
    email: string;
  };
}

export const CREATE_INVITATION = gql`
  mutation inviteAdmin($data: InvitationCreateInput!) {
    inviteAdmin(data: $data) {
      id
      email

      created
      expired
    }
  }
`;
