import { Button } from "@mui/material";
import { FC } from "react";
import { useStyles } from "./ReadyToPublish.styles";
import {
  ConstructionOutlined as ConstructionOutlinedIcon,
  DoneAllOutlined as DoneAllOutlinedIcon,
} from "@mui/icons-material";

interface IProps {
  published: boolean;
  handlePublish: () => void;
}

export const ReadyToPublish: FC<IProps> = (props) => {
  const { published, handlePublish } = props;
  const { classes } = useStyles();

  return (
    <Button
      onClick={handlePublish}
      color={published ? "success" : "info"}
      variant={"contained"}
      startIcon={
        published ? <DoneAllOutlinedIcon /> : <ConstructionOutlinedIcon />
      }
    >
      {published ? "Vidljivo javnosti" : "Skica"}
    </Button>
  );
};
