import {
  DeleteOutlined as DeleteOutlinedIcon,
  EditOutlined as EditOutlinedIcon,
} from "@mui/icons-material";
import { Button, TableCell, TableRow } from "@mui/material";
import { FC } from "react";
import { IAdmin } from "../../../apollo/queries";
import { useGlobalStyles } from "../../../styles/theme";

interface IProps {
  index: number;
  data: IAdmin;
  handleDelete: () => void;
  handleUpdate: () => void;
}

export const AdministratorItem: FC<IProps> = (props) => {
  const { name, email } = props.data;
  const { handleDelete, handleUpdate, index } = props;

  // const { classes } = useStyles();
  const { classes: globalClasses } = useGlobalStyles();

  return (
    <TableRow onClick={() => {}}>
      <TableCell>{index}</TableCell>
      <TableCell>
        <strong>{name}</strong>
      </TableCell>
      <TableCell>{email}</TableCell>

      <TableCell>
        <div className={globalClasses.tableActionButtons}>
          <Button
            startIcon={<EditOutlinedIcon />}
            onClick={handleUpdate}
            size="small"
          >
            Uredi
          </Button>
          <Button
            startIcon={<DeleteOutlinedIcon />}
            onClick={handleDelete}
            size="small"
          >
            Obriši
          </Button>
        </div>
      </TableCell>
    </TableRow>
  );
};
