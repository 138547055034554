import { createTypedDynamicFormInputs } from "../../../utils";

export const initialInputData = createTypedDynamicFormInputs({
  title: {
    inputProps: {
      label: "Naziv",
      name: "title",
      placeholder: "Park prirode ...",
      required: true,
    },
    mainHelperText: "Naziv slike. Važan i za dokumente (kao naziv linka).",
  },
  alt: {
    inputProps: {
      label: "Opis",
      name: "alt",
      placeholder:
        "Sunčan dan u parku s igralištem, velikim drvećem i ljudima koji hodaju stazom",
      required: true,
    },
    mainHelperText:
      "Ako je slika dekorativna (Npr: plava pozadina s tekstom), staviti 'x' bez navodnika. Opis je jako važan za pristupačnost web stranice.",
  },
});
