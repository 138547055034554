import { Paper } from "@mui/material";
import { FC } from "react";
import { MediaSelectorContent, PageLayout } from "../../../components";
import { useGlobalStyles } from "../../../styles/theme";
// import { useStyles } from "./MediaPage.styles";

export const MediaPage: FC = () => {
  // const { classes } = useStyles();
  const { classes: globalClasses } = useGlobalStyles();

  return (
    <PageLayout justifyCenter>
      <Paper className={globalClasses.paperRoot}>
        <MediaSelectorContent />
      </Paper>
    </PageLayout>
  );
};
