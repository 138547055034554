import { createTypedDynamicFormInputs } from "../../../utils/formUtils";

export const initialInputData = createTypedDynamicFormInputs({
  title: {
    inputProps: {
      label: "Naslov",
      name: "title",
      placeholder: "Moj naslov",
      required: true,
    },
    mainHelperText: "Naslov za stranicu. Izbjegavati duge naslove",
  },
  content: {
    rule: "slateRte",
    mainHelperText:
      "This short description describes the general theme and what the visitors can expect when playing the Interactive tour.",
    inputProps: {
      name: "content",
      label: "Sadržaj",
      placeholder: "Glavni sadržaj stranice",
      required: false,
    },
  },
  parent: {
    mainHelperText:
      "Kategorija označava pod kojim uvijetima i na kojim mjestima se prikazuje ova stranica",
    inputProps: {
      name: "parent",
      label: "Kategorija",
      placeholder: "...",
      required: false,
    },
  },
  slug: {
    inputProps: {
      name: "slug",
      label: "Slug",
      placeholder: "moja-stranica",
      required: true,
    },
    mainHelperText:
      "Dio URL-a koji identificira određenu stranicu. Koristi se kako bi se URL-ovi učinili opisnijima i prijateljskijima prema korisnicima, poboljšavajući čitljivost i optimizaciju za tražilice (SEO).",
  },
  header: {
    inputProps: {
      name: "header",
      label: "Naslovna slika",
      placeholder: "A catchy title",
    },
    rule: "media",
  },
  showNestedPosts: {
    isCheckbox: true,
    inputProps: {
      name: "showNestedPosts",
      label: "Prikaži potkategorije",
    },
    mainHelperText: "Prikaži podstranice ispod sadržaja ove stranice.",
  },
  featured: {
    isCheckbox: true,
    inputProps: {
      name: "featured",
      label: "Istakni stranicu",
    },
    mainHelperText:
      "Istaknute stranice se prikazuju na početnoj stranici. Može postojati samo jedna istaknuta stranica.",
  },
});
