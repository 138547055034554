import { gql } from "@apollo/client";

export const F_POST = gql`
  fragment FPost on Post {
    id
    title
    contentRaw
    parentId

    header {
      id
      fileName
      bucketPath
      byteSize
      mimeType
      extension
      title
      alt
    }
    featured
    locked
    published
    slug
    showNestedPosts

    created
  }
`;
