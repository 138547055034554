import { makeStyles } from "tss-react/mui";

export const useStyles = makeStyles()((theme) => ({
  // root: {
  //   display: "flex",
  //   alignItems: "center",
  //   justifyContent: "space-between",
  //   marginLeft: theme.spacing(4),
  // },
  // firstDiv: {
  //   display: "flex",
  //   alignItems: "center",
  // },
  // text: {
  //   marginRight: theme.spacing(2),
  //   marginLeft: theme.spacing(2),
  // },
}));
