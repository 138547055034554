import {
  CheckOutlined as CheckOutlinedIcon,
  DeleteOutlined as DeleteOutlinedIcon,
  DocumentScannerOutlined as DocumentScannerOutlinedIcon,
  EditOutlined as EditOutlinedIcon,
  PreviewOutlined as PreviewOutlinedIcon,
  WarningOutlined as WarningOutlinedIcon,
} from "@mui/icons-material";
import {
  ButtonBase,
  IconButton,
  Paper,
  Tooltip,
  Typography,
} from "@mui/material";
import { FC } from "react";
import { IMedia } from "../../../../apollo/queries";
import { ALLOWED_IMAGES_TYPES } from "../../../../utils";
import { useStyles } from "./MediaItem.styles";

interface IProps {
  isSelected?: number;
  onClick?: (event: any) => void;
  handleOpenDeleteDialog?: (event: any) => void;
  handleOpenUpdateDialog?: (event: any) => void;
  handleOpenPreviewDialog?: (event: any) => void;
  max?: number;
  item: IMedia;
}

export const MediaItem: FC<IProps> = (props) => {
  const {
    onClick,
    isSelected,
    handleOpenDeleteDialog,
    handleOpenUpdateDialog,
    handleOpenPreviewDialog,
  } = props;
  const { bucketPath, fileName, mimeType, title, extension, alt } = props.item;
  const { classes } = useStyles();

  return (
    <Paper
      className={`${classes.mediaContainer} ${
        isSelected ? classes.selectedPaper : ""
      }`}
    >
      <ButtonBase className={classes.button} focusRipple onClick={onClick}>
        {(ALLOWED_IMAGES_TYPES as string[]).includes(mimeType) ? (
          <img
            src={`${process.env.REACT_APP_BUCKET_ENDPOINT}${bucketPath}`}
            alt=""
            className={classes.image}
          />
        ) : (
          <div className={classes.center}>
            <DocumentScannerOutlinedIcon
              //@ts-ignore
              className={`${classes.audioIcon} ${classes[extension as any]}`}
              // color="secondary"
            />
            <Typography color="primary" fontWeight={600} marginTop={1}>
              {extension.toUpperCase()}
            </Typography>
          </div>
        )}
      </ButtonBase>
      <Typography className={classes.imageTitle}>
        {title || fileName}
      </Typography>
      {onClick ? (
        <>
          <Tooltip title="Uredi medij">
            <IconButton
              size="small"
              color="primary"
              className={classes.editIcon}
              onClick={handleOpenUpdateDialog}
            >
              <EditOutlinedIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Obriši medij">
            <IconButton
              size="small"
              color="primary"
              className={classes.deleteIcon}
              onClick={handleOpenDeleteDialog}
            >
              <DeleteOutlinedIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Pregledaj medij">
            <IconButton
              size="small"
              color="primary"
              className={classes.expandIcon}
              onClick={handleOpenPreviewDialog}
            >
              <PreviewOutlinedIcon />
            </IconButton>
          </Tooltip>
        </>
      ) : null}

      {!title || !alt ? (
        <Tooltip title="Ovaj medij nema naslov i/ili opis!">
          <IconButton
            size="small"
            color="error"
            className={classes.warningIcon}
          >
            <WarningOutlinedIcon />
          </IconButton>
        </Tooltip>
      ) : null}
      {onClick && isSelected ? (
        <IconButton
          size="small"
          color="primary"
          className={classes.selectedIcon}
        >
          <Typography>{isSelected}</Typography>
          <CheckOutlinedIcon fontSize="small" />
        </IconButton>
      ) : null}
    </Paper>
  );
};
