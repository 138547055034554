import { useMutation, useQuery } from "@apollo/client";
import { useSnackbar } from "notistack";
import { FC, ReactNode, useEffect, useRef, useState } from "react";
import { useStyles } from "./MediaSelectorContent.styles";
import {
  Button,
  ButtonGroup,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";
import { LoadingBackdrop } from "../other/loadingBackdrop/LoadingBackdrop";
import { PublishOutlined as PublishOutlinedIcon } from "@mui/icons-material";
import {
  CREATE_MEDIA,
  DELETE_MEDIA,
  ICreateMediaData,
  ICreateMediaVars,
  IDeleteMediaData,
  IDeleteMediaVars,
} from "../../apollo/mutations";
import {
  ALL_MEDIAS,
  IMediasData,
  IMediasVars,
  IMediaWithoutCount,
} from "../../apollo/queries";
import {
  ALLOWED_DOCUMENTS_TYPES,
  ALLOWED_MEDIA_TYPES,
  IMediaDialogContext,
  IMediaSelectDialogContext,
  MAX_DOCUMENT_UPLOAD_SIZE,
  MAX_IMAGE_UPLOAD_SIZE,
} from "../../utils";
import byteSize from "byte-size";
import { DataHandlerComponent, DeleteDialog, UpdateMediaDialog } from "../";
import { MediaItem, MediaPreviewDialog } from "./components";
import { useGlobalStyles } from "../../styles/theme";

interface IProps {
  isDialog?: boolean;
  dialogContext?: IMediaSelectDialogContext;
  onClose?: () => void;
}

interface IPropsPaperContainer {
  children: ReactNode;
}

const PaperContainer: FC<IPropsPaperContainer> = (props) => {
  const { classes: globalClasses } = useGlobalStyles();

  return <div className={globalClasses.paperContainer}>{props.children}</div>;
};

const PaperTitle: FC<IPropsPaperContainer> = (props) => {
  const { classes: globalClasses } = useGlobalStyles();

  return (
    <div className={globalClasses.paperTitle}>
      <Typography variant="h5" component="h1">
        Mediji
      </Typography>
    </div>
  );
};

export const MediaSelectorContent: FC<IProps> = (props) => {
  const { dialogContext, isDialog, onClose } = props;
  const [currentType, setCurrentType] = useState<
    IMediaSelectDialogContext["type"]
  >(dialogContext?.type);

  const { classes } = useStyles();
  const inputRef = useRef<HTMLInputElement>(null);
  const { enqueueSnackbar } = useSnackbar();
  const [selectedMedia, setSelectedMedia] = useState<IMediaWithoutCount[]>([]);

  const [onDragEnterToggle, setOnDragEnterToggle] = useState(false);
  const [openDialog, setOpenDialog] = useState<IMediaDialogContext>({
    open: false,
    item: undefined,
    type: undefined,
  });

  const handleSelectAll = () => {
    setCurrentType(undefined);
  };

  const handleSelectImages = () => {
    setCurrentType("image");
  };

  const handleSelectDocuments = () => {
    setCurrentType("document");
  };

  const handleCloseDialog = () => {
    setOpenDialog((prevState) => ({ ...prevState, open: false }));
  };

  const { data, loading, error } = useQuery<IMediasData, IMediasVars>(
    ALL_MEDIAS,
    {
      variables: {
        type: currentType,
      },
    }
  );

  const [createMediaMutation, { loading: loadingCreateMutation }] = useMutation<
    ICreateMediaData,
    ICreateMediaVars
  >(CREATE_MEDIA, {
    onCompleted: (data) => {
      // console.log(data);
      enqueueSnackbar(`Medij je uspješno učitan!`, {
        variant: "success",
      });
    },
    onError: (err) => {
      // console.warn("Error: ", { err });
      enqueueSnackbar(`Neuspješno učitavanje medija: ${err.message}`, {
        variant: "error",
      });
    },
    refetchQueries: [
      { query: ALL_MEDIAS, variables: { type: "image" } },
      { query: ALL_MEDIAS, variables: { type: "document" } },
      { query: ALL_MEDIAS, variables: { type: undefined } },
    ],
    // update: (cache, { data }) => {
    //   if (!data?.createMedia) {
    //     enqueueSnackbar("Došlo je do nepoznate greške.", {
    //       variant: "error",
    //     });
    //     return;
    //   }

    //   const newMediaData: IMedia = {
    //     ...data.createMedia,
    //     // _count: {
    //     //   StationMedia: 0,
    //     //   TourMedia: 0,
    //     // },
    //   };

    //   //Specific media type

    //   const existingListData: IMediasData | null = cache.readQuery({
    //     query: ALL_MEDIAS,
    //   });
    //   cache.writeQuery({
    //     query: ALL_MEDIAS,
    //     data: {
    //       allMedias: existingListData?.allMedias
    //         ? [newMediaData, ...existingListData.allMedias]
    //         : [newMediaData],
    //     },
    //   });
    // },
  });

  const [deleteMediaMutation, { loading: loadingDeleteMutation }] = useMutation<
    IDeleteMediaData,
    IDeleteMediaVars
  >(DELETE_MEDIA, {
    onCompleted: (data) => {
      enqueueSnackbar(`Medij je obrisan: ${data.deleteMedia.fileName}!`, {
        variant: "success",
      });
      handleCloseDialog();
    },
    onError: (err) => {
      enqueueSnackbar(`Greška prilikom brisanja medija: ${err.message}`, {
        variant: "error",
      });
    },
  });

  const validateFiles = (file: File) => {
    if ((ALLOWED_MEDIA_TYPES as string[]).indexOf(file.type) === -1) {
      enqueueSnackbar(
        `Krivi format medija: ${file.type || "Nepoznati format"}`,
        {
          variant: "error",
        }
      );
      return false;
    }
    const maxSize = dialogContext?.type
      ? dialogContext.type === "image"
        ? MAX_IMAGE_UPLOAD_SIZE
        : MAX_DOCUMENT_UPLOAD_SIZE
      : MAX_DOCUMENT_UPLOAD_SIZE;

    if (file.size > maxSize) {
      enqueueSnackbar(
        `Medij je prevelik: ${byteSize(
          file.size
        )}! Mora biti manji od ${byteSize(maxSize)}`,
        {
          variant: "error",
        }
      );
      return false;
    }
    return true;
  };

  const handleUpload = async (file: File) => {
    createMediaMutation({
      variables: {
        file: file,
      },
    });
  };

  const handleManualInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target?.files?.length) {
      const files = event.target.files;
      if (files.length > 10) {
        enqueueSnackbar("Možete učitati maksimalno 10 medija odjednom!", {
          variant: "error",
        });
        return null;
      }
      for (let i = 0; i < files.length; i++) {
        if (validateFiles(files[i])) {
          handleUpload(files[i]);
        } else {
        }
      }
    }
    event.target.value = "";
  };

  const handleFileDrop = (files: FileList) => {
    if (files.length > 10) {
      enqueueSnackbar("Možete učitati maksimalno 10 medija odjednom!", {
        variant: "error",
      });
      return null;
    }
    for (let i = 0; i < files.length; i++) {
      if (validateFiles(files[i])) {
        handleUpload(files[i]);
      }
    }
  };

  const handleOnDragEnter = (event: React.DragEvent<HTMLButtonElement>) => {
    event.preventDefault();
    setOnDragEnterToggle(true);
  };

  const handleOnDragLeave = (event: React.DragEvent<HTMLButtonElement>) => {
    event.preventDefault();
    setOnDragEnterToggle(false);
  };

  const handleOnDragOver = (event: React.DragEvent<HTMLButtonElement>) => {
    event.preventDefault();
    setOnDragEnterToggle(true);
  };

  const handleOnDragDrop = (event: React.DragEvent<HTMLButtonElement>) => {
    event.preventDefault();
    const files = event.dataTransfer.files;
    if (files.length) {
      handleFileDrop(files);
    }
    setOnDragEnterToggle(false);
  };

  const handleOnClick = () => {
    if (inputRef) {
      //@ts-ignore
      inputRef.current.click();
    }
  };

  const handleDeleteMedia = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    if (openDialog.item?.id) {
      const id = openDialog.item.id;
      deleteMediaMutation({
        variables: {
          id: +id,
        },
        update(cache) {
          cache.modify({
            fields: {
              allMedias(existingMedias, { readField }) {
                return existingMedias.filter(
                  (taskRef: any) => id !== readField("id", taskRef)
                );
              },
            },
          });
        },
      });
    } else {
      enqueueSnackbar("ID nije pronađen!", { variant: "error" });
    }
  };

  const handleOnConfirm = () => {
    dialogContext?.onChange?.(selectedMedia);
    onClose?.();
    enqueueSnackbar(`${selectedMedia.length} medija odabrano!`, {
      variant: "info",
    });
  };

  useEffect(() => {
    if (dialogContext?.selectedMedia?.length) {
      setSelectedMedia([...dialogContext.selectedMedia]);
    }
  }, [dialogContext?.selectedMedia]);

  const DialogOrNotContent = isDialog ? DialogContent : PaperContainer;
  const DialogOrNotTitle = isDialog ? DialogTitle : PaperTitle;

  return (
    <>
      <DialogOrNotTitle>Mediji</DialogOrNotTitle>
      <DialogOrNotContent>
        <Button
          variant={onDragEnterToggle ? "text" : "outlined"}
          color={onDragEnterToggle ? "secondary" : "inherit"}
          onDragOver={handleOnDragOver}
          onDragEnter={handleOnDragEnter}
          onDragLeave={handleOnDragLeave}
          onDrop={handleOnDragDrop}
          className={
            onDragEnterToggle
              ? classes.uploadButtonActive
              : classes.uploadButton
          }
          onClick={handleOnClick}
        >
          <PublishOutlinedIcon className={classes.uploadAreaIcon} />
        </Button>
        <input
          ref={inputRef}
          multiple={dialogContext?.max && dialogContext?.max > 1 ? true : false}
          accept={ALLOWED_MEDIA_TYPES.join(",")}
          type="file"
          className={classes.hidden}
          tabIndex={-1}
          onChange={handleManualInput}
        />
        {dialogContext?.type === undefined ? (
          <ButtonGroup>
            <Button
              variant={currentType === undefined ? "contained" : "outlined"}
              onClick={handleSelectAll}
            >
              Sve
            </Button>
            <Button
              variant={currentType === "image" ? "contained" : "outlined"}
              onClick={handleSelectImages}
            >
              Slike
            </Button>
            <Button
              variant={currentType === "document" ? "contained" : "outlined"}
              onClick={handleSelectDocuments}
            >
              Dokumenti
            </Button>
          </ButtonGroup>
        ) : null}
        <div className={classes.container}>
          <DataHandlerComponent
            error={Boolean(error)}
            hasData={Boolean(data?.allMedias.length)}
            loading={loading}
          >
            {data?.allMedias.length
              ? data.allMedias.map((item) => {
                  const handleOpenPreviewDialog = () => {
                    if (ALLOWED_DOCUMENTS_TYPES.includes(item.mimeType)) {
                      return;

                      // if (!item.) {
                      //   const fetchedBlob = await fetch(
                      //     `${process.env.REACT_APP_BUCKET_TARGET}${path}`,
                      //     {
                      //       method: "GET",
                      //     }
                      //   );
                      //   const resolvedBlob = await fetchedBlob.blob();
                      //   fileDownload(resolvedBlob, name, type);
                      // } else {
                      //   fileDownload(blob, name, type);
                      // }
                    }
                    setOpenDialog({
                      open: true,
                      item: item,
                      type: "preview",
                    });
                  };
                  const handleOpenDeleteDialog = () => {
                    setOpenDialog({
                      open: true,
                      item: item,
                      type: "delete",
                    });
                  };
                  const handleOpenUpdateDialog = () => {
                    setOpenDialog({
                      open: true,
                      item: item,
                      type: "update",
                    });
                  };

                  const isSelected = selectedMedia.findIndex(
                    (x) => x.id === item.id
                  );

                  const handleSelectMedia = () => {
                    const newSelectedMedia = [...selectedMedia];
                    const index = newSelectedMedia.findIndex(
                      (x) => x.id === item.id
                    );

                    if (index === -1) {
                      if (
                        (dialogContext?.max === 1 || !dialogContext?.max) &&
                        selectedMedia.length
                      ) {
                        const itemExists = data?.allMedias.find(
                          (x) => x.id === item.id
                        );

                        if (itemExists) {
                          if (!itemExists.title || !itemExists.alt) {
                            enqueueSnackbar(
                              `Ovaj medij nema naslov i/ili opis!`,
                              {
                                variant: "error",
                              }
                            );
                            return;
                          }

                          newSelectedMedia.splice(index, 1);
                          newSelectedMedia.push(itemExists);
                          setSelectedMedia([...newSelectedMedia]);
                        } else {
                          enqueueSnackbar(`Došlo je do nepoznate greške!`, {
                            variant: "error",
                          });
                        }
                        return;
                      }

                      if (
                        dialogContext?.max
                          ? selectedMedia.length >= dialogContext.max
                          : selectedMedia.length
                      ) {
                        enqueueSnackbar(
                          `Možete odabrati do ${dialogContext?.max} medija!`,
                          {
                            variant: "warning",
                          }
                        );
                        return null;
                      }

                      const itemExists = data?.allMedias.find(
                        (x) => x.id === item.id
                      );
                      if (itemExists) {
                        if (!itemExists.title || !itemExists.alt) {
                          enqueueSnackbar(
                            `Ovaj medij nema naslov i/ili opis!`,
                            {
                              variant: "error",
                            }
                          );
                          return;
                        }
                        newSelectedMedia.push(itemExists);
                        setSelectedMedia([...newSelectedMedia]);
                      } else {
                        enqueueSnackbar(`Došlo je do nepoznate greške!`, {
                          variant: "error",
                        });
                      }
                    } else {
                      // console.log("index", index);
                      newSelectedMedia.splice(index, 1);
                      setSelectedMedia([...newSelectedMedia]);
                    }
                  };

                  return (
                    <MediaItem
                      onClick={
                        isDialog ? handleSelectMedia : handleOpenPreviewDialog
                      }
                      key={item.id}
                      item={item}
                      handleOpenDeleteDialog={handleOpenDeleteDialog}
                      handleOpenUpdateDialog={handleOpenUpdateDialog}
                      handleOpenPreviewDialog={handleOpenPreviewDialog}
                      isSelected={isSelected === -1 ? 0 : isSelected + 1}
                    />
                  );
                })
              : null}
          </DataHandlerComponent>
        </div>

        <DeleteDialog
          open={openDialog.open && openDialog.type === "delete"}
          onClose={handleCloseDialog}
          title={`Obrišite ${openDialog.item?.fileName}`}
          description="Ova radnja je trajna!"
          mutation={handleDeleteMedia}
        />
        <MediaPreviewDialog
          open={openDialog.open && openDialog.type === "preview"}
          onClose={handleCloseDialog}
          item={openDialog.item}
        />
        <UpdateMediaDialog
          open={openDialog.open && openDialog.type === "update"}
          onClose={handleCloseDialog}
          item={openDialog.item}
        />
        <LoadingBackdrop
          loading={loadingCreateMutation || loadingDeleteMutation}
        />
      </DialogOrNotContent>

      {isDialog ? (
        <DialogActions>
          <Typography className={classes.selectText} color="textSecondary">
            Odaberite medije i kliknite na "Potvrdi"
          </Typography>

          <Button variant="outlined" onClick={onClose}>
            Odustani
          </Button>
          <Button variant="contained" onClick={handleOnConfirm}>
            Potvrdi
          </Button>
        </DialogActions>
      ) : null}
    </>
  );
};
