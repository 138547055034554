import "./index.css";
import "./globals.css";
import { withProps } from "@udecode/cn";
import {
  createPlugins,
  Plate,
  RenderAfterEditable,
  PlateElement,
  PlateLeaf,
  useEditorRef,
} from "@udecode/plate-common";
import {
  createParagraphPlugin,
  ELEMENT_PARAGRAPH,
} from "@udecode/plate-paragraph";
import {
  createHeadingPlugin,
  ELEMENT_H2,
  ELEMENT_H3,
  ELEMENT_H4,
  ELEMENT_H5,
  ELEMENT_H6,
  KEYS_HEADING,
} from "@udecode/plate-heading";
import {
  createBlockquotePlugin,
  ELEMENT_BLOCKQUOTE,
} from "@udecode/plate-block-quote";
import {
  createHorizontalRulePlugin,
  ELEMENT_HR,
} from "@udecode/plate-horizontal-rule";
import { createLinkPlugin, ELEMENT_LINK } from "@udecode/plate-link";
import { createImagePlugin, ELEMENT_IMAGE } from "@udecode/plate-media";
import { createTogglePlugin, ELEMENT_TOGGLE } from "@udecode/plate-toggle";
import {
  createColumnPlugin,
  ELEMENT_COLUMN_GROUP,
  ELEMENT_COLUMN,
} from "@udecode/plate-layout";
import {
  createListPlugin,
  ELEMENT_UL,
  ELEMENT_OL,
  ELEMENT_LI,
} from "@udecode/plate-list";
import { createCaptionPlugin } from "@udecode/plate-caption";
import {
  createBoldPlugin,
  MARK_BOLD,
  createItalicPlugin,
  MARK_ITALIC,
  createUnderlinePlugin,
  MARK_UNDERLINE,
  createStrikethroughPlugin,
  MARK_STRIKETHROUGH,
} from "@udecode/plate-basic-marks";
import { createFontColorPlugin } from "@udecode/plate-font";
import { createIndentPlugin } from "@udecode/plate-indent";
import { createBlockSelectionPlugin } from "@udecode/plate-selection";
import { createDndPlugin } from "@udecode/plate-dnd";
import {
  createExitBreakPlugin,
  createSoftBreakPlugin,
} from "@udecode/plate-break";
import { createNodeIdPlugin } from "@udecode/plate-node-id";
import { createResetNodePlugin } from "@udecode/plate-reset-node";
import { createDeletePlugin } from "@udecode/plate-select";
import { createTabbablePlugin } from "@udecode/plate-tabbable";
import { createTrailingBlockPlugin } from "@udecode/plate-trailing-block";
import { createDeserializeDocxPlugin } from "@udecode/plate-serializer-docx";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";

import { BlockquoteElement } from "./plate-ui/blockquote-element";
import { HrElement } from "./plate-ui/hr-element";
import { ImageElement } from "./plate-ui/image-element";
import { LinkElement } from "./plate-ui/link-element";
import { LinkFloatingToolbar } from "./plate-ui/link-floating-toolbar";
import { ToggleElement } from "./plate-ui/toggle-element";
import { ColumnGroupElement } from "./plate-ui/column-group-element";
import { ColumnElement } from "./plate-ui/column-element";
import { HeadingElement } from "./plate-ui/heading-element";
import { ListElement } from "./plate-ui/list-element";
import { ParagraphElement } from "./plate-ui/paragraph-element";
import { Editor } from "./plate-ui/editor";
import { FixedToolbar } from "./plate-ui/fixed-toolbar";
import { FixedToolbarButtons } from "./plate-ui/fixed-toolbar-buttons";
import { FloatingToolbar } from "./plate-ui/floating-toolbar";
import { FloatingToolbarButtons } from "./plate-ui/floating-toolbar-buttons";
import { withPlaceholders } from "./plate-ui/placeholder";
import { withDraggables } from "./plate-ui/with-draggables";
import { TooltipProvider } from "./plate-ui/tooltip";
import { FC, useEffect } from "react";
import { useStyles } from "./PlateRte.styles";

export const plugins = createPlugins(
  [
    createParagraphPlugin(),
    createHeadingPlugin(),
    createBlockquotePlugin(),
    createHorizontalRulePlugin(),
    createLinkPlugin({
      renderAfterEditable: LinkFloatingToolbar as RenderAfterEditable,
    }),
    createImagePlugin(),
    // createTogglePlugin(),
    // createColumnPlugin(),
    // createCaptionPlugin({
    //   options: {
    //     pluginKeys: [
    //       ELEMENT_IMAGE,
    //       // ELEMENT_MEDIA_EMBED
    //     ],
    //   },
    // }),
    createBoldPlugin(),
    createItalicPlugin(),
    createUnderlinePlugin(),
    createStrikethroughPlugin(),
    // createFontColorPlugin(),
    createIndentPlugin({
      inject: {
        props: {
          validTypes: [
            ELEMENT_PARAGRAPH,
            // ELEMENT_H1, ELEMENT_H2, ELEMENT_H3, ELEMENT_BLOCKQUOTE, ELEMENT_CODE_BLOCK
          ],
        },
      },
    }),
    createBlockSelectionPlugin({
      options: {
        sizes: {
          top: 0,
          bottom: 0,
        },
      },
    }),
    createDndPlugin({
      options: { enableScroller: true },
    }),
    createExitBreakPlugin({
      options: {
        rules: [
          {
            hotkey: "mod+enter",
          },
          {
            hotkey: "mod+shift+enter",
            before: true,
          },
          {
            hotkey: "enter",
            query: {
              start: true,
              end: true,
              allow: KEYS_HEADING,
            },
            relative: true,
            level: 1,
          },
        ],
      },
    }),
    // createNodeIdPlugin(),
    // createResetNodePlugin({
    //   options: {
    //     rules: [
    //       // Usage: https://platejs.org/docs/reset-node
    //     ],
    //   },
    // }),
    createDeletePlugin(),
    // createSoftBreakPlugin({
    //   options: {
    //     rules: [
    //       { hotkey: "shift+enter" },
    //       {
    //         hotkey: "enter",
    //         query: {
    //           allow: [
    //             // ELEMENT_CODE_BLOCK, ELEMENT_BLOCKQUOTE, ELEMENT_TD
    //           ],
    //         },
    //       },
    //     ],
    //   },
    // }),
    createListPlugin(),
    createTabbablePlugin(),
    createTrailingBlockPlugin({
      options: { type: ELEMENT_PARAGRAPH },
    }),
    // createDeserializeDocxPlugin(),
  ],
  {
    components: withDraggables(
      withPlaceholders({
        [ELEMENT_BLOCKQUOTE]: BlockquoteElement,
        [ELEMENT_HR]: HrElement,
        [ELEMENT_IMAGE]: ImageElement,
        [ELEMENT_LINK]: LinkElement,
        [ELEMENT_TOGGLE]: ToggleElement,
        [ELEMENT_COLUMN_GROUP]: ColumnGroupElement,
        [ELEMENT_COLUMN]: ColumnElement,
        [ELEMENT_H2]: withProps(HeadingElement, { variant: "h2" }),
        [ELEMENT_H3]: withProps(HeadingElement, { variant: "h3" }),
        [ELEMENT_H4]: withProps(HeadingElement, { variant: "h4" }),
        [ELEMENT_H5]: withProps(HeadingElement, { variant: "h5" }),
        [ELEMENT_H6]: withProps(HeadingElement, { variant: "h6" }),
        [ELEMENT_UL]: withProps(ListElement, { variant: "ul" }),
        [ELEMENT_OL]: withProps(ListElement, { variant: "ol" }),
        [ELEMENT_LI]: withProps(PlateElement, { as: "li" }),
        [ELEMENT_PARAGRAPH]: ParagraphElement,
        [MARK_BOLD]: withProps(PlateLeaf, { as: "strong" }),
        [MARK_ITALIC]: withProps(PlateLeaf, { as: "em" }),
        [MARK_STRIKETHROUGH]: withProps(PlateLeaf, { as: "s" }),
        [MARK_UNDERLINE]: withProps(PlateLeaf, { as: "u" }),
      })
    ),
  }
);

export const rteInitialValue = [
  {
    id: "1",
    type: "p",
    children: [{ text: "" }],
  },
];

interface IProps {
  onBlur: () => void;
  initialValue?: string | null | undefined;
  setInputFields: (callback: any) => void;
  editorRef: any;
}
export const PlateRteEditor: FC<IProps> = (props) => {
  const thisEditorRef = useEditorRef();

  useEffect(() => {
    // if (props.initialValue) {
    //   const parsedRte = deserializeHtml(thisEditorRef, {
    //     element: props.initialValue,
    //   });
    //   // const parsedRte = deserializeHtml(editorRef.current, {
    //   //   nodes: editorRef.current.children,
    //   // });
    //   // const point = { path: [0, 0], offset: 0 };
    //   // editorRef.current.selection = { anchor: point, focus: point };
    //   // editorRef.current.history = { redos: [], undos: [] };
    //   // editorRef.current.children = parsedRte as Value;
    //   console.log(
    //     "DES",
    //     deserializeHtml(thisEditorRef, {
    //       element: props.initialValue,
    //     })
    //   );
    //   props.setInputFields((prev: any) => ({
    //     ...prev,
    //     content: {
    //       ...prev.content,
    //       inputProps: {
    //         ...prev.content.inputProps,
    //         value: parsedRte,
    //       },
    //       initialValues: {
    //         ...prev.content.initialValues,
    //         unlocalised: {
    //           hasChanged: false,
    //           media: [],
    //           value: props.initialValue || "",
    //           rteValue: parsedRte as Value,
    //         },
    //       },
    //     },
    //   }));
    // }
  }, [props.initialValue, thisEditorRef]);

  const { classes } = useStyles();

  return (
    <TooltipProvider
      disableHoverableContent
      delayDuration={500}
      skipDelayDuration={0}
    >
      <DndProvider backend={HTML5Backend}>
        <Plate
          plugins={plugins}
          // initialValue={
          //   props.initialValue
          //     ? (deserializeHtml(thisEditorRef, {
          //         element: props.initialValue,
          //       }) as Value)
          //     : rteInitialValue
          // }
          editorRef={props.editorRef}
        >
          <FixedToolbar>
            <FixedToolbarButtons />
          </FixedToolbar>

          <Editor
            className={`min-h-80 max-h-[800px] ${classes.root}`}
            onBlur={props.onBlur}
          />

          <FloatingToolbar>
            <FloatingToolbarButtons />
          </FloatingToolbar>
        </Plate>
      </DndProvider>
    </TooltipProvider>
  );
};
