import { makeStyles } from "tss-react/mui";

export const useStyles = makeStyles()((theme) => ({
  root: {
    position: "fixed",
    // backgroundColor: theme.palette.background.paper,
    height: 60,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: `16px 20px`,
    zIndex: theme.zIndex.drawer + 2,
    backgroundColor: "#222222",

    top: 0,
    left: 0,
    right: 0,

    borderBottomWidth: 1,
    borderBottomColor: "#111111",
    borderBottomStyle: "solid",
  },
  logo: {
    height: 28,
  },
  subjectNameDiv: {
    flex: 1,
    borderLeftColor: theme.palette.grey[300],
    borderLeftWidth: 1,
    borderLeftStyle: "solid",
    marginLeft: 16,
    paddingLeft: 16,
  },

  iconButton: {
    marginLeft: 4,
    borderColor: theme.palette.grey[300],
    borderWidth: 1,
    "& svg": {
      transition: "0.25s all ease-in-out",
    },
    "&:hover svg": {
      transition: "0.25s all ease-in-out",
      color: theme.palette.primary.main,
    },
  },

  //Profile
  active: {
    color: theme.palette.primary.main,
  },
  menuSmallText: {
    fontSize: 12,
  },
  menuSmallTextActive: {
    fontSize: 12,
    fontWeight: 600,
  },
}));
