import { gql } from "@apollo/client";
import { F_POST } from "../../fragments";
import { IPost } from "../../queries";

export interface IUpdatePostData {
  updatePost: IPost;
}
export interface IUpdatePostVars {
  id: number;
  data: {
    content: string | undefined | null;
    contentRaw: string | undefined | null;
    title: string | undefined | null;
    headerId: number | undefined | null;
    slug: string | undefined | null;
    parentId: number | undefined | null;
    showNestedPosts: boolean | undefined | null;
    featured: boolean | undefined | null;
  };
}

export const UPDATE_POST = gql`
  ${F_POST}
  mutation updatePost($id: ID!, $data: PostUpdateInput!) {
    updatePost(id: $id, data: $data) {
      ...FPost
    }
  }
`;

//*------ UPDATE PUBLISHED

export interface IUpdatePostPublished {
  id: string;
  published: boolean;
}

export interface IUpdatePostPublishedData {
  updatePostPublished: IUpdatePostPublished | null;
}
export interface IUpdatePostPublishedVars {
  id: number;
  publish: boolean;
}

export const UPDATE_POST_PUBLISHED = gql`
  mutation updatePostPublished($id: ID!, $publish: Boolean!) {
    updatePostPublished(id: $id, publish: $publish) {
      id
      published
    }
  }
`;
