import { gql } from "@apollo/client";

export interface IInvitation {
  id: string;
  email: string;
  created: string;
  expired: string | null;
}

export interface IInvitationsData {
  allInvitations: IInvitation[];
}

export const ALL_INVITATIONS = gql`
  query allInvitations {
    allInvitations {
      id
      email

      created
      expired
    }
  }
`;
