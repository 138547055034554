import { useCallback, useEffect, useRef, useState } from "react";
import { IMedia } from "../../apollo/queries";
import {
  IDynamicFormInitialInput,
  IDynamicFormInput,
  localizeInputValues,
} from "../formUtils";
import { useGlobalStyles } from "../../styles/theme";
import deepCopy from "deepcopy";
import { closeSnackbar, useSnackbar } from "notistack";
import { Button } from "@mui/material";
import { NaratourError, handleCatchError } from "../naratourError";
import { isObject } from "../helpers";
import { PlateEditor } from "@udecode/plate-common";

export const useForm = <T extends string | number | symbol>(
  initialInputData: Record<T, IDynamicFormInitialInput>,
  editor?: PlateEditor
) => {
  // const { languageFlags } = useContext(ContextProvider);
  const languageFlags: any[] = [];
  const { classes } = useGlobalStyles();
  const skippedInitalRender = useRef(false);
  const { enqueueSnackbar } = useSnackbar();
  const [formError, setFormError] = useState(false);
  const [activeLocale, setActiveLocale] = useState("");
  const [currentLanguageFlags, setCurrentLanguageFlags] = useState<any[]>(
    //< ILanguageFlag[]>
    []
  );

  const getInitialInputFields = useCallback(
    (inputData: Record<T, IDynamicFormInitialInput>, setState?: boolean) => {
      const localisedInput = setState
        ? (inputData as Record<T, IDynamicFormInput>)
        : localizeInputValues<keyof typeof inputData>(inputData, languageFlags);

      Object.keys(localisedInput).forEach((key) => {
        const thisKey = key as keyof typeof initialInputData;

        localisedInput[thisKey].inputProps.onChange = (event: any) => {
          // console.log("INNER LOCALE: ", activeLocale);
          setInputFields((prevState) => {
            const localeOrNot =
              activeLocale && prevState[thisKey].localise
                ? activeLocale
                : "unlocalised";
            const isMediaRule = prevState[thisKey].rule === "media";
            const isSlateRteRule = prevState[thisKey].rule === "slateRte";
            const returnValueProperty = isMediaRule
              ? "media"
              : isSlateRteRule
              ? "rteValue"
              : "value";

            if (prevState[thisKey].regexPattern && !isMediaRule) {
              const passed = prevState[thisKey].regexPattern?.test?.(
                event.target.value
              );
              if (!passed) {
                return prevState;
              }
            }

            const initialValues = {
              ...prevState[thisKey].initialValues,
              [localeOrNot]: {
                ...prevState[thisKey].initialValues[localeOrNot],

                hasChanged: isMediaRule
                  ? prevState[thisKey].initialValues[localeOrNot].value !==
                    event.map((item: IMedia) => item.id).join(",")
                  : // : isSlateRteRule
                    // ? prevState[thisKey].initialValues[localeOrNot].value !==
                    //   serializeHtml(editor)
                    prevState[thisKey].initialValues[localeOrNot].value !==
                    event.target.value,
              },
            };

            const returnInputFields = {
              ...prevState,
              [thisKey]: {
                ...prevState[thisKey],
                inputProps: {
                  ...prevState[thisKey].inputProps,
                  error: false,
                  classes: initialValues[localeOrNot].hasChanged
                    ? // && initialValues[localeOrNot].shouldCheck
                      {
                        root: classes.inputHasChanged,
                      }
                    : undefined,
                },

                values: {
                  ...prevState[thisKey].values,
                  [localeOrNot]: {
                    ...prevState[thisKey].values[localeOrNot],
                    value: isMediaRule
                      ? event.map((item: IMedia) => item.id).join(",")
                      : // : isSlateRteRule
                        // ? rteSerialize(event)
                        event.target.value,
                    media: isMediaRule ? event : undefined,
                    rteValue: isSlateRteRule ? event : undefined,
                  },
                },
                initialValues: initialValues,
              },
            };

            returnInputFields[thisKey].inputProps.value =
              returnInputFields[thisKey].values[localeOrNot][
                returnValueProperty
              ];

            returnInputFields[thisKey].inputProps.helperText =
              returnInputFields[thisKey].inputProps.error
                ? returnInputFields[thisKey].mainErrorText
                : returnInputFields[thisKey].mainHelperText;

            return returnInputFields;
          });
        };

        const localeOrNot =
          activeLocale && localisedInput[thisKey].localise
            ? activeLocale
            : "unlocalised";
        const isMediaRule = localisedInput[thisKey].rule === "media";
        const returnValueProperty = isMediaRule ? "media" : "value";

        localisedInput[thisKey].inputProps.value =
          localisedInput[thisKey].values[localeOrNot][returnValueProperty];

        localisedInput[thisKey].inputProps.required = localisedInput[thisKey]
          .mainLocaleRequiredOnly
          ? activeLocale === ""
            ? true
            : false
          : localisedInput[thisKey].inputProps.required;

        localisedInput[thisKey].inputProps.helperText = localisedInput[thisKey]
          .inputProps.error
          ? localisedInput[thisKey].mainErrorText
          : localisedInput[thisKey].mainHelperText;
      });

      if (setState) {
        setInputFields(localisedInput as Record<T, IDynamicFormInput>);
        return localisedInput as Record<T, IDynamicFormInput>;
      } else {
        return localisedInput as Record<T, IDynamicFormInput>;
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [activeLocale]
  );

  const [inputFields, setInputFields] = useState<Record<T, IDynamicFormInput>>(
    () => getInitialInputFields(initialInputData, false)
  );

  const resetFields = (
    fieldsToReset?: T[],
    resetToCurrentStatus: boolean = false,
    allLocales: boolean = false
  ) => {
    let currentFields = fieldsToReset || (Object.keys(inputFields) as T[]);

    if (!currentFields.length) {
      enqueueSnackbar("Failed to reset fields. Please contact support!");
      return;
    }
    let dataToClear = {} as Record<T, IDynamicFormInitialInput>;

    currentFields.forEach((field) => {
      const localeOrNot =
        activeLocale && inputFields[field].localise
          ? activeLocale
          : "unlocalised";

      const isMediaRule = inputFields[field].rule === "media";
      const returnValueProperty = isMediaRule ? "media" : "value";

      //TODO: Finish all locales logic

      if (resetToCurrentStatus) {
        dataToClear = {
          ...dataToClear,
          [field]: {
            ...inputFields[field],
            inputProps: {
              ...inputFields[field].inputProps,
              classes: null,
            },

            initialValues: {
              ...inputFields[field].initialValues,
              [localeOrNot]: {
                ...inputFields[field].initialValues[localeOrNot],
                hasChanged: false,
                value: inputFields[field].values[localeOrNot].value,
                media: inputFields[field].values[localeOrNot].media,
              },
            },
          },
        };
      } else {
        dataToClear[field] = {
          ...inputFields[field],
          inputProps: {
            ...inputFields[field].inputProps,
            error: false,
            value: returnValueProperty === "media" ? [] : "",
            classes: null,
          },
          values: {
            ...inputFields[field].values,
            [localeOrNot]: {
              value: "",
              media: [],
            },
          },
          initialValues: {
            ...inputFields[field].initialValues,
            [localeOrNot]: {
              value: "",
              media: [],
              hasChanged: false,
            },
          },
        };
      }
    });
    // console.log("DATA TO CLEAR: ", dataToClear, activeLocale);
    setInputFields((prevState) => {
      return { ...prevState, ...dataToClear };
    });
  };

  const setInputField = (inputKey: T, value: any): void => {
    setInputFields((prevState) => {
      const localeOrNot =
        activeLocale && inputFields[inputKey].localise
          ? activeLocale
          : "unlocalised";
      const isMediaValue = inputFields[inputKey].rule === "media";
      const isSlateRteValue = inputFields[inputKey].rule === "slateRte";
      const returnValueProperty = isMediaValue
        ? "media"
        : isSlateRteValue
        ? "rteValue"
        : "value";

      const initialValues = prevState[inputKey].initialValues
        ? {
            ...prevState[inputKey].initialValues,
            [localeOrNot]: {
              ...prevState[inputKey].initialValues![localeOrNot],
              hasChanged: isSlateRteValue
                ? prevState[inputKey].initialValues![localeOrNot]?.value !==
                  JSON.stringify(value)
                : prevState[inputKey].initialValues![localeOrNot]?.value !==
                  value,
            },
          }
        : undefined;

      const toReturnData = {
        ...prevState,
        [inputKey]: {
          ...prevState[inputKey],
          inputProps: {
            ...prevState[inputKey].inputProps,
            error: false,
          },
          values: {
            ...prevState[inputKey].values,
            [localeOrNot]: {
              ...prevState[inputKey].values[localeOrNot],
              value: isSlateRteValue ? JSON.stringify(value) : value,
              rteValue: isSlateRteValue ? value : undefined,
            },
          },
          initialValues: initialValues,
        },
      };

      toReturnData[inputKey].inputProps.value =
        toReturnData[inputKey].values[localeOrNot][returnValueProperty];

      return toReturnData;
    });
  };

  //String
  function handleDataToVar(
    field: T,
    returnType: "string"
  ): string | undefined | null;
  function handleDataToVar(
    field: T,
    returnType: "string",
    nullable?: false
  ): string | undefined;
  //Number
  function handleDataToVar(
    field: T,
    returnType: "number"
  ): number | undefined | null;
  function handleDataToVar(
    field: T,
    returnType: "number",
    nullable?: false
  ): number | undefined;
  //Date
  function handleDataToVar(
    field: T,
    returnType: "date"
  ): Date | undefined | null;
  function handleDataToVar(
    field: T,
    returnType: "date",
    nullable?: false
  ): Date | undefined;
  //StringArray
  function handleDataToVar(
    field: T,
    returnType: "stringArray"
  ): string[] | undefined | null;
  function handleDataToVar(
    field: T,
    returnType: "stringArray",
    nullable?: false
  ): string[] | undefined;
  //NumberArray
  function handleDataToVar(
    field: T,
    returnType: "numberArray"
  ): number[] | undefined | null;
  function handleDataToVar(
    field: T,
    returnType: "numberArray",
    nullable?: false
  ): number[] | undefined;
  //Boolean
  function handleDataToVar(
    field: T,
    returnType: "boolean"
  ): boolean | undefined | null;
  function handleDataToVar(
    field: T,
    returnType: "boolean",
    nullable?: false
  ): boolean | undefined;

  function handleDataToVar(
    field: T,
    returnType:
      | "number"
      | "boolean"
      | "stringArray"
      | "date"
      | "string"
      | "numberArray",
    nullable: boolean = true
  ): number | string | string[] | Date | boolean | undefined | null | number[] {
    let returnValue = undefined;
    // console.log("DATATOVAR: ", field, inputFields[field]);

    const localeOrNot =
      activeLocale && inputFields[field].localise
        ? activeLocale
        : "unlocalised";

    if (!inputFields[field].initialValues) {
      // console.log("IT IS EMPTY OR NEW");
      if (!inputFields[field].values[localeOrNot].value) {
        // console.log("VALUE IS EMPTY");

        return undefined;
      }
      // console.log("VALUE IS NEW");
      returnValue = inputFields[field].values[localeOrNot].value;
    } else if (
      inputFields[field].values[localeOrNot].value !==
      inputFields[field].initialValues?.[localeOrNot].value
    ) {
      // console.log("IS AN UPDATED VALUE");
      if (nullable) {
        // console.log("IS NULLABLE");
        if (inputFields[field].values[localeOrNot].value) {
          // console.log("IS NOT NULL");

          returnValue = inputFields[field].values[localeOrNot].value;
        } else {
          // console.log("IS NULL");
          returnValue = null;
        }
      } else {
        // console.log("RETURN UPDATED VALUE");
        returnValue = inputFields[field].values[localeOrNot].value;
      }
    } else {
      // console.log("VALUE REMAINS SAME");
      return undefined;
    }

    if (returnValue && returnType === "number") {
      // console.log("RETURN NUMBER");
      return +returnValue;
    } else if (returnValue && returnType === "date") {
      // console.log("FIX DATE");
    } else if (returnType === "boolean") {
      // console.log("RETURN BOOLEAN");
      return Boolean(returnValue);
    } else if (returnType === "stringArray") {
      if (returnValue) {
        // console.log("RETURN ARRAY");
        // console.log("SPLIT: ", returnValue.split(","));
        return returnValue.split(",");
      } else {
        // console.log("RETURN EMPTY ARRAY");

        return [];
      }
    } else if (returnType === "numberArray") {
      if (returnValue) {
        // console.log("RETURN NUMBER ARRAY");
        // console.log("SPLIT: ", returnValue.split(","));
        return returnValue.split(",").map((x) => +x);
      } else {
        // console.log("RETURN EMPTY ARRAY");

        return [];
      }
    } else {
      // console.log("RETURN ELSE");
      return returnValue;
    }
  }

  useEffect(() => {
    if (skippedInitalRender.current) {
      const newInputFields = deepCopy(inputFields);

      Object.keys(newInputFields).forEach((key) => {
        const thisKey = key as keyof typeof initialInputData;

        const localeOrNot =
          activeLocale && newInputFields[thisKey].localise
            ? activeLocale
            : "unlocalised";
        const isMediaRule = newInputFields[thisKey].rule === "media";
        const isSlateRteRule = newInputFields[thisKey].rule === "slateRte";
        const returnValueProperty = isMediaRule
          ? "media"
          : isSlateRteRule
          ? "rteValue"
          : "value";

        newInputFields[thisKey].inputProps.onChange = (event: any) => {
          setInputFields((prevState) => {
            if (prevState[thisKey].regexPattern !== undefined && !isMediaRule) {
              // console.log(typeof prevState[thisKey].regexPattern);
              const passed = prevState[thisKey].regexPattern?.test?.(
                event.target.value
              );
              if (!passed) {
                return prevState;
              }
            }
            const initialValues = {
              ...prevState[thisKey].initialValues,
              [localeOrNot]: {
                ...prevState[thisKey].initialValues[localeOrNot],

                hasChanged: isMediaRule
                  ? prevState[thisKey].initialValues[localeOrNot].value !==
                    event.map((item: IMedia) => item.id).join(",")
                  : isSlateRteRule
                  ? prevState[thisKey].initialValues[localeOrNot].value !==
                    event //TODO: Check RTE
                  : prevState[thisKey].initialValues[localeOrNot].value !==
                    event.target.value,
              },
            };

            const returnInputFields = {
              ...prevState,
              [thisKey]: {
                ...prevState[thisKey],
                inputProps: {
                  ...prevState[thisKey].inputProps,
                  error: false,
                  classes: initialValues[localeOrNot].hasChanged
                    ? {
                        root: classes.inputHasChanged,
                      }
                    : undefined,
                },

                values: {
                  ...prevState[thisKey].values,
                  [localeOrNot]: {
                    ...prevState[thisKey].values[localeOrNot],
                    value: isMediaRule
                      ? event.map((item: IMedia) => item.id).join(",")
                      : // : isSlateRteRule
                        // ? rteSerialize(event)
                        event.target.value,
                    media: isMediaRule ? event : [],
                    rteValue: isSlateRteRule ? event : undefined,
                  },
                },
                initialValues: initialValues,
              },
            };

            returnInputFields[thisKey].inputProps.value =
              returnInputFields[thisKey].values[localeOrNot][
                returnValueProperty
              ];

            returnInputFields[thisKey].inputProps.helperText =
              returnInputFields[thisKey].inputProps.error
                ? returnInputFields[thisKey].mainErrorText
                : returnInputFields[thisKey].mainHelperText;

            return returnInputFields;
          });
        };

        newInputFields[thisKey].inputProps.classes = newInputFields[thisKey]
          .initialValues[localeOrNot].hasChanged
          ? {
              root: classes.inputHasChanged,
            }
          : undefined;

        newInputFields[thisKey].inputProps.required = newInputFields[thisKey]
          .mainLocaleRequiredOnly
          ? activeLocale === ""
            ? true
            : false
          : newInputFields[thisKey].inputProps.required;

        newInputFields[thisKey].inputProps.value =
          newInputFields[thisKey].values[localeOrNot][returnValueProperty];

        newInputFields[thisKey].inputProps.helperText = newInputFields[thisKey]
          .inputProps.error
          ? newInputFields[thisKey].mainErrorText
          : newInputFields[thisKey].mainHelperText;
      });
      setInputFields(newInputFields);

      // getInitialInputFields(inputFields, true);
    } else {
      skippedInitalRender.current = true;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeLocale]);

  const validateForm = (
    fields: Array<keyof Record<T, IDynamicFormInput>>,
    strict: boolean = true,
    allLocales: boolean = false
  ): boolean => {
    const outputData = deepCopy(inputFields);
    let formValid = true;
    let looseRequiredFields: string[] = [];
    let requiredFields: string[] = [];

    const getLabelAndLocale = (field: T, localeOrNot: string) => {
      return `${outputData[field].inputProps.label}${
        localeOrNot !== "unlocalised"
          ? ` (${
              outputData[field].mainLocaleRequiredOnly ? "" + "*" : localeOrNot
            })`
          : ""
      }`;
    };
    try {
      if (!fields.length || !Object.keys(outputData).length) {
        return formValid;
      }

      fields.forEach((field) => {
        let fieldInvalid = false;
        let errorText = "";
        const localeOrNot =
          activeLocale && outputData[field].localise
            ? outputData[field].mainLocaleRequiredOnly
              ? ""
              : activeLocale
            : "unlocalised";

        const makeInvalid = (message?: string) => {
          fieldInvalid = true;
          formValid = false;
          errorText = message || "";
        };

        const checkIfInvalid = (message: string, locale: string) => {
          if (!outputData[field].inputProps.required) {
            return;
          }

          // console.log("Check: ", locale);
          if (!outputData[field].values[locale].value) {
            if (outputData[field].strictRequired || strict) {
              makeInvalid(message);
              requiredFields.push(getLabelAndLocale(field, locale));
            } else {
              looseRequiredFields.push(getLabelAndLocale(field, locale));
            }
          } else {
            if (outputData[field].rule === "email") {
              const pattern =
                /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
              const checkPattern = pattern.test(
                outputData[field].values[locale].value
              );
              if (!checkPattern) {
                makeInvalid("Email nije ispravnog formata!");
                requiredFields.push(getLabelAndLocale(field, locale));
              }
            }
          }
        };

        if (
          allLocales &&
          outputData[field].localise &&
          !outputData[field].mainLocaleRequiredOnly
        ) {
          currentLanguageFlags.forEach((locale) => {
            checkIfInvalid(
              `Obavezno polje nije upisano!${` (${
                outputData[field].mainLocaleRequiredOnly
                  ? "" + " only"
                  : locale.isoLanguageCode
              })`}`,
              locale.isoLanguageCode
            );
          });
        } else {
          checkIfInvalid(
            `Obavezno polje nije upisano!${
              localeOrNot !== "unlocalised"
                ? ` (${
                    outputData[field].mainLocaleRequiredOnly
                      ? "" + " only"
                      : activeLocale
                  })`
                : ""
            }`,
            localeOrNot
          );
        }
        outputData[field] = {
          ...outputData[field],
          mainErrorText: errorText
            ? errorText
            : outputData[field].mainErrorText, //?check
          inputProps: {
            ...outputData[field].inputProps,
            error: fieldInvalid,
            helperText: fieldInvalid
              ? errorText
              : outputData[field].mainHelperText, //?check
          },
        };
      });
    } catch (error) {
      formValid = false;
      if (error instanceof Error) {
        enqueueSnackbar(error.message, { variant: "error" });
      } else {
        enqueueSnackbar("Unknown error", { variant: "error" });
      }
    } finally {
      if (looseRequiredFields.length) {
        enqueueSnackbar(
          `Sljedeća polja su obavezna, možete ih kasnije ispuniti: ${looseRequiredFields.join(
            ", "
          )}.`,
          {
            preventDuplicate: true,
            variant: "info",
            autoHideDuration: 8000,
            action: (key) => (
              <Button
                color="inherit"
                variant="text"
                size="small"
                onClick={() => closeSnackbar(key)}
              >
                Dismiss
              </Button>
            ),
          }
        );
      }
      if (!formValid) {
        enqueueSnackbar(
          `Sljedeća polja su obavezna: ${requiredFields.join(", ")}.`,
          { variant: "error" }
        );
      }
      setInputFields((prevState) => ({ ...prevState, ...outputData }));
      return formValid;
    }
  };

  const getFormValuesFromFetchedData = (
    data: { [key: string]: any },
    valueToFormOptions: { fromDataProperty: string; toFormProperty: T }[],
    getFlags: boolean = true,
    setState: boolean = true
  ) => {
    const inputFieldsCopy = deepCopy(inputFields);
    //*-----
    try {
      valueToFormOptions.forEach((item, i) => {
        const findCorrectData = (
          data: any,
          substring: string[],
          foundLocales: string[] = []
        ) => {
          // console.log("---------------FIND CORRECT DATA-----------------");
          // console.log("-CURRENT DATA: ", data);
          // console.log("-CURRENT ITEM: ", item.toFormProperty);
          // console.log("-CURRENT SUBSTRING: ", substring);
          //*---------
          if (!substring.length) {
            // console.log("-END - NO MORE SUBSTRINGS+++++++");
            return;
          }
          const sub = substring[0];
          // substring.forEach((sub, ii) => {
          const nextSubString = substring.slice(1);
          const specificField = inputFieldsCopy[item.toFormProperty];
          // console.log("---CURRENT SUB: ", sub);

          const foundItem = data?.[sub];
          // console.log("------FOUND SUB ITEM: ", foundItem, sub);

          if (data?.languageFlag?.isoLanguageCode) {
            // console.log("FOUND NEW FLAG: ", data.languageFlag.isoLanguageCode);
            foundLocales.push(data.languageFlag.isoLanguageCode);
          }

          const lastFoundLocale = foundLocales.length
            ? foundLocales[foundLocales.length - 1]
            : "unlocalised";

          const toCurrentInputPropLocale =
            activeLocale && specificField.localise
              ? activeLocale
              : "unlocalised";

          if (!data?.hasOwnProperty?.(sub)) {
            throw new NaratourError(
              `Failed to locale property from data while filling input fields. Property: ${item.fromDataProperty} -> (${sub}). Refresh the page to try again or contact support.`
            );
          }

          if (
            foundItem === null ||
            foundItem === undefined ||
            foundItem === ""
          ) {
            // console.log("--------IS EMPTY!");
            //? Dont do anything?
            specificField.initialValues[lastFoundLocale] = {
              ...specificField.initialValues[lastFoundLocale],
              // shouldCheck: true,
            };
            return;
          } else if (typeof foundItem === "boolean") {
            const toReturnValue = foundItem ? "TRUE" : "";
            specificField.values[lastFoundLocale].value = toReturnValue;

            specificField.inputProps.value =
              specificField.values[toCurrentInputPropLocale].value;

            specificField.initialValues[lastFoundLocale] = {
              ...specificField.initialValues[lastFoundLocale],
              value: toReturnValue,
              // shouldCheck: true,
            };
          } else if (typeof foundItem === "string") {
            //Only if media is not in array
            if (specificField.rule === "media") {
              specificField.values[lastFoundLocale].media = []; //Reset field due to cache updates
              specificField.values[lastFoundLocale].value = "";

              specificField.values[lastFoundLocale].media = [data];

              specificField.inputProps.value =
                specificField.values[toCurrentInputPropLocale].media;
              // // //TODO: ----

              specificField.values[lastFoundLocale].value = foundItem;

              specificField.initialValues[lastFoundLocale] = {
                ...specificField.initialValues[lastFoundLocale],
                value: specificField.values[lastFoundLocale].value,
                media: specificField.values[lastFoundLocale].media,
                // shouldCheck: true,
              };
              // // //TODO: ----

              return;
            }

            // console.log("--------RETURN STRING!");
            specificField.values[lastFoundLocale].value = foundItem;

            //TODO: Fix this below
            specificField.inputProps.value =
              specificField.values[toCurrentInputPropLocale].value;

            specificField.initialValues[lastFoundLocale] = {
              ...specificField.initialValues[lastFoundLocale],
              value: foundItem,
              // shouldCheck: true,
            };
            return;
          } else if (typeof foundItem === "number") {
            // console.log("--------RETURN NUMBER!");
            specificField.values[lastFoundLocale].value = foundItem.toString();
            // console.log(lastFoundLocale, toCurrentInputPropLocale);
            specificField.inputProps.value =
              specificField.values[toCurrentInputPropLocale].value;

            specificField.initialValues[lastFoundLocale] = {
              ...specificField.initialValues[lastFoundLocale],
              value: foundItem.toString(),
              // shouldCheck: true,
            };
            return;
          } else if (Array.isArray(foundItem)) {
            foundItem.forEach((arrItem, iii) => {
              // console.log("--------ARRAY ITEM FOUND!!: ", arrItem, i, iii);

              if (
                specificField.rule === "media" &&
                nextSubString.length === 1
              ) {
                // console.log("--------RETURN MEDIA STRING!");
                // console.log("--------LAST FOUND LOCALE!", lastFoundLocale, data);

                // specificField.values[lastFoundLocale].media.push(data);
                specificField.values[lastFoundLocale].media = []; //Reset field due to cache updates
                specificField.values[lastFoundLocale].value = "";

                specificField.values[lastFoundLocale].media = foundItem;

                // console.log("--------specificField.values", specificField.values);

                // // console.log(
                // //   "%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%",
                // //   specificField.values[lastFoundLocale].media
                // // );
                // // //TODO: Do this in the end
                specificField.inputProps.value =
                  specificField.values[toCurrentInputPropLocale].media;
                // // //TODO: ----

                // console.log("Foudn item", foundItem, sub);
                // console.log( "", substring, nextSubString );

                const nextSub = nextSubString[0];

                specificField.values[lastFoundLocale].value = foundItem
                  .map((item) => item[nextSub])
                  .join(",");

                // console.log(
                //   "value: ",
                //   specificField.values[lastFoundLocale].value
                // );

                // // //TODO: Do this in the end
                specificField.initialValues[lastFoundLocale] = {
                  ...specificField.initialValues[lastFoundLocale],
                  value: specificField.values[lastFoundLocale].value,
                  media: specificField.values[lastFoundLocale].media,
                  // shouldCheck: true,
                };
                // // //TODO: ----

                return;
              }

              findCorrectData(arrItem, nextSubString, foundLocales);
            });

            return;
          } else if (isObject(foundItem)) {
            //TODO: NEEDED?
            // console.log("--------OBJECT! GO DEEPER!");
            findCorrectData(foundItem, nextSubString, foundLocales);
            return;
          } else {
            // console.log("--------NOTHING ELSE FOUND", sub, substring, data);
            return;
          }
        };

        const mainSubString = item.fromDataProperty.split(".");
        findCorrectData(data, mainSubString);
      });
      if (getFlags) {
        if (data?.allLocales?.length) {
          const selectFilteredFlags = languageFlags.filter((y: any) =>
            data.allLocales?.some((x: any) => x.languageFlag.id === y.id)
          );
          selectFilteredFlags.sort((a: any, b: any) => {
            if (a.isoLanguageCode === "") {
              return -1;
            } else if (a.isoCountryCode > b.isoCountryCode) {
              return 1;
            } else if (a.isoCountryCode < b.isoCountryCode) {
              return -1;
            } else return 0;
          });
          setCurrentLanguageFlags(selectFilteredFlags);
        }
      }

      if (setState) {
        setInputFields((prevState) => ({ ...prevState, ...inputFieldsCopy }));
      } else {
        return inputFieldsCopy;
      }
    } catch (err) {
      setFormError(true);
      handleCatchError(err);
    }
  };

  const didValuesChange = (fields?: T[], allLocales: boolean = false) => {
    // console.log("CHECK FOR CHANGES");
    let toCheck = fields;
    if (!toCheck?.length) {
      toCheck = Object.keys(inputFields) as T[];
    }

    const changeDetected = toCheck.some((field) => {
      if (allLocales) {
        return Object.keys(inputFields[field].initialValues).some((locale) => {
          return inputFields[field].initialValues[locale].hasChanged;
        });
      } else {
        const localeOrNot =
          activeLocale && inputFields[field].localise
            ? activeLocale
            : "unlocalised";

        return inputFields[field].initialValues[localeOrNot].hasChanged;
      }
    });
    // console.log("CHANGE DETECTED: ", changeDetected);
    return changeDetected;
  };

  return {
    inputFields,
    setInputFields,
    inputFieldNames: Object.keys(inputFields) as T[],
    resetFields,
    setInputField,
    handleDataToVar,
    validateForm,
    getFormValuesFromFetchedData,
    formError,
    activeLocale,
    setActiveLocale,
    currentLanguageFlags,
    setCurrentLanguageFlags,
    didValuesChange,
  } as const;
};
