import { gql } from "@apollo/client";

export interface ICheckAdminInviteData {
  checkAdminInvite: boolean;
}

export interface ICheckAdminInviteVars {
  token: string;
}

export const CHECK_ADMIN_INVITE = gql`
  mutation checkAdminInvite($token: NonEmptyString!) {
    checkAdminInvite(token: $token)
  }
`;

export interface IRevokeInvitation {
  id: string;
  expired: string | null;
}

export interface IRevokeInvitationData {
  revokeInvitation: IRevokeInvitation;
}

export interface IRevokeInvitationVars {
  id: number;
}

export const REVOKE_INVITATION = gql`
  mutation revokeInvitation($id: ID!) {
    revokeInvitation(id: $id) {
      id
      expired
    }
  }
`;

export interface IResendInvitation {
  id: string;
  email: string;
  created: string;
  expired: string | null;
}

export interface IResendInvitationData {
  //*Created / Updated
  resendInvitation: [IResendInvitation, IResendInvitation];
}

export interface IResendInvitationVars {
  id: number;
}

export const RESEND_INVITATION = gql`
  mutation resendInvitation($id: ID!) {
    resendInvitation(id: $id) {
      id
      email

      created
      expired
    }
  }
`;
