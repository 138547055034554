import { gql } from "@apollo/client";
import { F_POST } from "../../fragments";
import { IPost } from "../../queries";

export interface IDeletePostData {
  deletePost: IPost;
}

export interface IDeletePostVars {
  id: number;
}

export const DELETE_POST = gql`
  ${F_POST}
  mutation deletePost($id: ID!) {
    deletePost(id: $id) {
      ...FPost
    }
  }
`;
