import { gql } from "@apollo/client";
import { F_POST } from "../../fragments";
import { IPost } from "../../queries";

export interface ICreatePostData {
  createPost: IPost;
}

export interface ICreatePostVars {
  data: {
    title: string | undefined;
    content: string | undefined;
    contentRaw: string | undefined;
    slug: string | undefined;
    headerId: number | undefined;
    parentId: number | undefined;
    showNestedPosts: boolean | undefined | null;
    featured: boolean | undefined | null;
  };
}

export const CREATE_POST = gql`
  ${F_POST}
  mutation createPost($data: PostCreateInput!) {
    createPost(data: $data) {
      ...FPost
    }
  }
`;
