import { makeStyles } from "tss-react/mui";

export const useStyles = makeStyles()((theme) => ({
  documentIcon: {
    fontSize: 24,
    marginRight: theme.spacing(1),
  },
  pdf: {
    color: theme.palette.error.main,
  },
  doc: {
    color: theme.palette.secondary.dark,
  },
  docx: {
    color: theme.palette.secondary.dark,
  },
  xls: {
    color: theme.palette.success.main,
  },
  xlsx: {
    color: theme.palette.success.main,
  },
}));
