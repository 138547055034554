import { useState } from "react";
import { ContextProvider } from "./utils";
import { MainRouter } from "./routes/MainRouter";
import { CURRENT_USER, ICurrentUser, ICurrentUserData } from "./apollo/queries";
import { useQuery } from "@apollo/client";
import { useSnackbar } from "notistack";
import { ErrorComponent, LoadingBackdrop } from "./components";

function App() {
  const [user, setUser] = useState<ICurrentUser | null | undefined>(undefined);
  const { enqueueSnackbar } = useSnackbar();

  const contextValue = {
    user: user,
    setUser: setUser,
    languageFlags: [],
    setLanguageFlags: () => {},
  };

  const { loading, error } = useQuery<ICurrentUserData>(CURRENT_USER, {
    onCompleted: async (data) => {
      if (!data.currentUser) {
        setUser(null);
        //TODO: Logout?
        // enqueueSnackbar(
        //   "You do not have the required permission to access this website!",
        //   { variant: "error" }
        // );
        // await logoutMutation(); //? Needed
        return;
      }
      setUser(data.currentUser);
    },
    onError: (error) => {
      enqueueSnackbar(error?.message, { variant: "error" });
    },
    fetchPolicy: "network-only",
  });

  return (
    <ContextProvider.Provider value={contextValue}>
      {error ? (
        <ErrorComponent />
      ) : loading ? (
        <LoadingBackdrop loading />
      ) : (
        <MainRouter />
      )}
    </ContextProvider.Provider>
  );
}

export default App;
