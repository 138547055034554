import { gql } from "@apollo/client";

export interface IUpdateMedia {
  id: string;
  fileName: string;
  bucketPath: string;
  byteSize: number;
  mimeType: string;
  extension: string;
  title: string | null;
  alt: string | null;
}

export interface IUpdateMediaData {
  updateMedia: IUpdateMedia;
}
export interface IUpdateMediaVars {
  id: number;
  data: {
    title: string | null | undefined;
    alt: string | null | undefined;
  };
}

export const UPDATE_MEDIA = gql`
  mutation updateMedia($id: ID!, $data: MediaUpdateInput!) {
    updateMedia(id: $id, data: $data) {
      id
      fileName
      bucketPath
      byteSize
      mimeType
      extension
      title
      alt
    }
  }
`;
