import { gql } from "@apollo/client";

export interface IMedia {
  id: string;
  fileName: string;
  bucketPath: string;
  byteSize: number;
  mimeType: string;
  extension: string;

  title: string | null;
  alt: string | null;
  // _count: {
  //   StationMedia: number;
  //   TourMedia: number;
  // };
}

export interface IMediaWithoutCount {
  id: string;
  fileName: string;
  bucketPath: string;
  byteSize: number;
  mimeType: string;
  extension: string;
  title: string | null;
  alt: string | null;
}

export interface IMediasData {
  allMedias: IMedia[];
}

export interface IMediasVars {}

export const ALL_MEDIAS = gql`
  query allMedias($type: String) {
    allMedias(type: $type) {
      id
      fileName
      bucketPath
      byteSize
      mimeType
      extension
      title
      alt
      # _count {
      #   StationMedia
      #   TourMedia
      # }
    }
  }
`;

export interface IMediaData {
  oneMedia: IMedia;
}

export interface IMediaVars {}

export const ONE_MEDIA = gql`
  query oneMedia($id: ID!) {
    oneMedia(id: $id) {
      id
      fileName
      bucketPath
      byteSize
      mimeType
      extension
      title
      alt
      # _count {
      #   StationMedia
      #   TourMedia
      # }
    }
  }
`;
