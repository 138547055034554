import {
  LogoutOutlined as LogoutOutlinedIcon,
  PersonOutlined as PersonOutlinedIcon,
} from "@mui/icons-material";
import {
  ClickAwayListener,
  Grow,
  IconButton,
  ListItemIcon,
  ListItemText,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  Typography,
} from "@mui/material";
import { useSnackbar } from "notistack";
import { FC, useContext, useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { useStyles } from "./Header.styles";
import logo from "../../../assets/logo.png";
import { LoadingBackdrop } from "../../other/loadingBackdrop/LoadingBackdrop";
import { ResetPasswordDialog } from "../../dialogs/resetPasswordDialog/ResetPasswordDialog";

import { ContextProvider } from "../../../utils";
import { ILogoutData, LOGOUT } from "../../../apollo/mutations";
import { useMutation } from "@apollo/client";

export const Header: FC = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { classes, cx } = useStyles();
  const { user, setUser } = useContext(ContextProvider);

  const [resetPasswordDialog, setResetPasswordDialog] = useState(false);
  const [openProfile, setOpenProfile] = useState(false);
  const anchorRef = useRef<HTMLButtonElement>(null);

  const [logoutMutation, { loading, client }] = useMutation<ILogoutData, null>(
    LOGOUT,
    {
      onCompleted: async () => {
        enqueueSnackbar("Odjava uspješna", { variant: "success" });
        setUser(null);
        await client.clearStore();
      },
      onError: (err) => {
        enqueueSnackbar(err.message, { variant: "error" });
      },
    }
  );

  const handleLogout = (event: Event | React.SyntheticEvent) => {
    logoutMutation();
    handleClose(event);
  };

  const handleToggle = () => {
    setOpenProfile((prevOpen) => !prevOpen);
  };

  const handleCloseResetPasswordDialog = () => {
    setResetPasswordDialog(false);
  };

  const handleOpenResetPasswordDialog = () => {
    setResetPasswordDialog(true);
  };

  const handleClose = (event: Event | React.SyntheticEvent) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return;
    }

    setOpenProfile(false);
  };

  const handleListKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpenProfile(false);
    } else if (event.key === "Escape") {
      setOpenProfile(false);
    }
  };

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = useRef(openProfile);
  useEffect(() => {
    if (prevOpen.current === true && openProfile === false) {
      anchorRef.current!.focus();
    }

    prevOpen.current = openProfile;
  }, [openProfile]);

  //TODO: Fix navlink margin
  return (
    <>
      <div className={classes.root}>
        <Link className={classes.logo} to="/">
          <img
            src={logo}
            className={classes.logo}
            alt="CZK Dr. Ivan Kostrenčić Crikvenica logo"
          />
        </Link>
        {user ? (
          <>
            <div className={classes.subjectNameDiv}>
              <Typography variant="h5" component="h1" color="primary">
                Administracija CZK Dr. Ivan Kostrenčić Crikvenica
              </Typography>
            </div>
            <div>
              <IconButton
                ref={anchorRef}
                onClick={handleToggle}
                className={cx(classes.iconButton)}
              >
                <PersonOutlinedIcon
                  color={openProfile ? "primary" : "secondary"}
                />
              </IconButton>
              <Popper
                open={openProfile}
                anchorEl={anchorRef.current}
                role={undefined}
                placement="top-start"
                transition
                disablePortal
              >
                {({ TransitionProps, placement }) => (
                  <Grow
                    {...TransitionProps}
                    style={{
                      transformOrigin:
                        placement === "bottom-start"
                          ? "right bottom"
                          : "right top",
                    }}
                  >
                    <Paper>
                      <ClickAwayListener onClickAway={handleClose}>
                        <MenuList
                          autoFocusItem={openProfile}
                          id="composition-menu"
                          aria-labelledby="composition-button"
                          onKeyDown={handleListKeyDown}
                        >
                          <MenuItem divider onClick={handleClose}>
                            <ListItemText
                              secondary={user ? user?.email : "nepoznati email"}
                            >
                              {user ? user?.name : "nepoznati korisnik"}
                            </ListItemText>
                          </MenuItem>
                          <MenuItem disabled divider>
                            <ListItemText>Administrator</ListItemText>
                          </MenuItem>

                          <MenuItem onClick={handleOpenResetPasswordDialog}>
                            Promijeni lozinku
                          </MenuItem>
                          <MenuItem onClick={handleLogout}>
                            <ListItemIcon>
                              <LogoutOutlinedIcon />
                            </ListItemIcon>
                            <ListItemText>Odjava</ListItemText>
                          </MenuItem>
                        </MenuList>
                      </ClickAwayListener>
                    </Paper>
                  </Grow>
                )}
              </Popper>
              <ResetPasswordDialog
                open={resetPasswordDialog}
                onClose={handleCloseResetPasswordDialog}
              />

              <LoadingBackdrop loading={loading} />
            </div>
          </>
        ) : null}
      </div>
    </>
  );
};
