import { gql } from "@apollo/client";
import { F_POST } from "../../fragments";
import { IMedia } from "../media/media.query";

export interface IPost {
  id: string;
  title: string | null;
  contentRaw: string | null;
  published: boolean;
  slug: string | null;
  locked: boolean;

  header: IMedia | null;
  parentId: string | null;
  showNestedPosts: boolean | null;

  created: string;
}

export interface IPostData {
  onePost: IPost | undefined;
}

export interface IPostsData {
  allPosts: IPost[];
}

export interface IPostVars {
  id: number;
}

export interface IPostsVars {
  query: string;
}

export const ONE_POST = gql`
  ${F_POST}
  query onePost($id: ID!) {
    onePost(id: $id) {
      ...FPost
    }
  }
`;

export const ALL_POSTS = gql`
  ${F_POST}
  query allPosts($query: String) {
    allPosts(query: $query) {
      ...FPost
    }
  }
`;
