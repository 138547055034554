import { gql } from "@apollo/client";

export interface ISlugStructure {
  slug: String[];
  id: string;
}

export interface ISlugStructureData {
  getSlugStructure: ISlugStructure[] | null;
}

export interface ISlugStructureVars {
  getAll: boolean;
}

export const GET_SLUG_STRUCTURE = gql`
  query getSlugStructure($getAll: Boolean) {
    getSlugStructure(getAll: $getAll) {
      slug
      id
    }
  }
`;
