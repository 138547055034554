import { makeStyles } from "tss-react/mui";

export const useStyles = makeStyles()((theme) => ({
  mediaContainer: {
    position: "relative",
    transition: "all 0.3s ease-in-out",
    transform: "scale(1)",
    flexBasis: "30%",
    height: 256,
    overflow: "hidden",
    marginBottom: theme.spacing(4),
    "&:hover": {
      transform: "scale(1.1)",
    },
    "&:hover > p": {
      opacity: 1,
    },
    "&:hover button": {
      opacity: 1,
      backgroundColor: "#ffffffff",
    },
  },
  button: {
    height: "100%",
    width: "100%",
  },
  selectedPaper: {
    boxShadow: `0 0 0 2px ${theme.palette.primary.main}`,
  },
  image: {
    objectFit: "cover",
    width: "100%",
    height: "100%",
    position: "absolute",
    bottom: 0,
    left: 0,
    right: 0,
    top: 0,
    borderRadius: theme.shape.borderRadius,
  },
  imageTitle: {
    position: "absolute",
    bottom: 0,
    left: 0,
    right: 0,
    padding: "0 8px",
    backgroundColor: "#33333399",
    color: "white",
    opacity: 0.5,
    transition: "all 0.3s ease-in-out",
  },
  editIcon: {
    position: "absolute",
    top: theme.spacing(1),
    left: theme.spacing(1),
    zIndex: 1,
    opacity: 0.7,
    transition: "all 0.3s ease-in-out",
    backgroundColor: "#ffffff77",
  },
  deleteIcon: {
    position: "absolute",
    top: theme.spacing(1),
    right: theme.spacing(6),
    zIndex: 1,
    opacity: 0.7,
    transition: "all 0.3s ease-in-out",
    backgroundColor: "#ffffff77",
  },
  expandIcon: {
    position: "absolute",
    top: theme.spacing(1),
    right: theme.spacing(1),
    zIndex: 1,
    opacity: 0.7,
    transition: "all 0.3s ease-in-out",
    backgroundColor: "#ffffff77",
  },
  warningIcon: {
    position: "absolute",
    top: theme.spacing(1),
    right: theme.spacing(11),
    zIndex: 1,
    backgroundColor: "#ffffff77",
  },
  selectedIcon: {
    position: "absolute",
    bottom: theme.spacing(5),
    right: theme.spacing(1),
    opacity: 1,
    zIndex: 1,
    backgroundColor: "#ffffffaa",
  },
  audioIcon: {
    fontSize: 64,
  },
  center: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
    pointerEvents: "none",
    flexDirection: "column",
  },

  doc: {
    color: "blue",
  },
  docx: {
    color: "blue",
  },
  pdf: {
    color: "coral",
  },
  xls: {
    color: "green",
  },
  xlsx: {
    color: "green",
  },
}));
