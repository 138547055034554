import { Description as DescriptionIcon } from "@mui/icons-material";
import { cn, withRef } from "@udecode/cn";
import { PlateElement, useElement } from "@udecode/plate-common";
import { type TLinkElement, useLink } from "@udecode/plate-link";
import { ALLOWED_DOCUMENT_EXTENSIONS } from "../../../utils";
import { useStyles } from "./link-element.styles";

export const LinkElement = withRef<typeof PlateElement>(
  ({ children, className, ...props }, ref) => {
    const element = useElement<TLinkElement>();
    const { classes } = useStyles();
    const { props: linkProps } = useLink({ element });

    const splitUrl = element.url.split(".");
    const splitUrlExtension = splitUrl[splitUrl.length - 1];

    return (
      <PlateElement
        asChild
        className={cn(
          "font-medium text-primary underline decoration-primary underline-offset-4",
          className
        )}
        ref={ref}
        {...(linkProps as any)}
        {...props}
      >
        <a>
          {ALLOWED_DOCUMENT_EXTENSIONS.includes(splitUrlExtension) ? (
            <DescriptionIcon
              // @ts-ignore
              className={`${classes.documentIcon} ${classes[splitUrlExtension]}`}
            />
          ) : null}
          {children}
        </a>
      </PlateElement>
    );
  }
);
