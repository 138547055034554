import { createTypedDynamicFormInputs } from "../../../utils";

export const initialInputData = createTypedDynamicFormInputs({
  name: {
    inputProps: {
      required: true,
      name: "name",
      label: "Ime",
    },
  },
  password: {
    inputProps: {
      required: true,
      name: "password",
      label: "Lozinka",
      type: "password",
    },
  },
});
