import {
  // AccessTimeOutlined as AccessTimeOutlinedIcon,
  DescriptionOutlined as DescriptionOutlinedIcon,
  PermMediaOutlined as PermMediaOutlinedIcon,
  PersonOutline as PersonOutlineIcon,
  //   SubtitlesOutlined as SubtitlesOutlinedIcon,
  //   TourOutlined as TourOutlinedIcon,
  //   SportsEsportsOutlined as SportsEsportsOutlinedIcon,
  //   PlagiarismOutlined as PlagiarismOutlinedIcon,
  //   ShoppingCartOutlined as ShoppingCartOutlinedIcon,
  //   CreditScoreOutlined as CreditScoreOutlinedIcon,
  //   PriceChangeOutlined as PriceChangeOutlinedIcon,
  //   AttachMoneyOutlined as AttachMoneyOutlinedIcon,
  //   HelpOutlineOutlined as HelpOutlineOutlinedIcon,
} from "@mui/icons-material";
import { OverridableComponent } from "@mui/material/OverridableComponent";
import { SvgIconTypeMap } from "@mui/material";

export enum ERouterPaths {
  LOGIN = "prijava",
  MEDIA = "mediji",
  POSTS = "stranice",
  ADMINISTRATORS = "administratori",
  OPENING_HOURS = "radni-sati",

  FORGOT_PASSWORD = "zaboravljena-lozinka",
  RESET_PASSWORD = "reset-password",
  JOIN = "join",
}

export interface INavbarData {
  id: string;
  title: string;
  route: ERouterPaths;
  icon: OverridableComponent<SvgIconTypeMap>;
  // access: ERoleAccess[];
  requiresCanSell?: boolean;
  external?: boolean;
  group: string;
}

export const navbarData: INavbarData[] = [
  {
    id: "0",
    title: "Stranice",
    route: ERouterPaths.POSTS,
    icon: DescriptionOutlinedIcon,
    group: "main",
  },
  {
    id: "1",
    title: "Mediji",
    route: ERouterPaths.MEDIA,
    icon: PermMediaOutlinedIcon,

    group: "main",
  },
  // {
  //   id: "2",
  //   title: "Radno vrijeme",
  //   route: ERouterPaths.OPENING_HOURS,
  //   icon: AccessTimeOutlinedIcon,
  //   group: "info",
  // },
  {
    id: "3",
    title: "Administratori",
    route: ERouterPaths.ADMINISTRATORS,
    icon: PersonOutlineIcon,
    group: "other",
  },
];
