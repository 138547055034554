import { FC, useContext, useEffect } from "react";
import {
  DialogTitle,
  DialogContent,
  Button,
  TextField,
  DialogActions,
  Dialog,
  Typography,
} from "@mui/material";
// import { useStyles } from "./UpsertAdminDialog.styles";
import { useSnackbar } from "notistack";
import { useLazyQuery, useMutation } from "@apollo/client";
import { initialInputData } from "./UpsertAdminDialog.inputs";
import { ContextProvider, IDialogContext, useForm } from "../../../utils";
import {
  ALL_INVITATIONS,
  IAdminData,
  IAdminVars,
  IInvitation,
  IInvitationsData,
  ONE_ADMIN,
} from "../../../apollo/queries";
import {
  CREATE_INVITATION,
  ICreateInvitationData,
  ICreateInvitationVars,
  IUpdateAdminData,
  IUpdateAdminVars,
  UPDATE_ADMIN,
} from "../../../apollo/mutations";
import { LoadingBackdrop } from "../../other/loadingBackdrop/LoadingBackdrop";
import { DataHandlerComponent } from "../../other/dataHandlerComponent/DataHandlerComponent";

interface IProps {
  onClose: () => void;
  open: boolean;
  type: IDialogContext["type"];
  id: undefined | string;
}

export const UpsertAdminDialog: FC<IProps> = (props) => {
  const { onClose, open, type, id } = props;
  const { enqueueSnackbar } = useSnackbar();

  const {
    inputFields,
    resetFields,
    validateForm,
    didValuesChange,
    getFormValuesFromFetchedData,
    handleDataToVar,
  } = useForm<keyof typeof initialInputData>(initialInputData);

  const [queryOneAdmin, { loading, data, error }] = useLazyQuery<
    IAdminData,
    IAdminVars
  >(ONE_ADMIN);

  const [updateAdminMutation, { loading: loadingUpdateAdminMutation }] =
    useMutation<IUpdateAdminData, IUpdateAdminVars>(UPDATE_ADMIN, {
      onCompleted: () => {
        enqueueSnackbar("Administrator je ažuriran.", {
          variant: "success",
        });
        onClose();
      },
      onError: (err) => {
        enqueueSnackbar(err.message, {
          variant: "error",
        });
      },
    });

  useEffect(() => {
    if (open) {
      if (id && type === "update") {
        queryOneAdmin({ variables: { id: +id } });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open, id, type]);

  const clearFields = () => {
    resetFields();
  };

  const [
    createPersonRoleSubjectInvitationMutation,
    { loading: loadingCreateInvitationMutation },
  ] = useMutation<ICreateInvitationData, ICreateInvitationVars>(
    CREATE_INVITATION,
    {
      onCompleted: (data) => {
        enqueueSnackbar(
          `Nova pozivnica je poslana na ${data.inviteAdmin.email}. Pozivnica je validna 7 dana!`,
          {
            variant: "success",
          }
        );
        onClose();
      },
      onError: (error) => {
        enqueueSnackbar(error.message, {
          variant: "error",
        });
      },
      update: (cache, { data }) => {
        const existingListData: IInvitationsData | null = cache.readQuery({
          query: ALL_INVITATIONS,
        });
        if (data?.inviteAdmin) {
          const newInvitationData: IInvitation = {
            ...data.inviteAdmin,
          };
          cache.writeQuery({
            query: ALL_INVITATIONS,
            data: {
              allInvitations: existingListData?.allInvitations
                ? [newInvitationData, ...existingListData.allInvitations]
                : [newInvitationData],
            },
          });
        }
      },
    }
  );

  const handleUpdateAdmin = () => {
    if (!validateForm(["name"])) {
      return;
    }
    if (id && type === "update") {
      updateAdminMutation({
        variables: {
          id: +id,
          data: {
            name: handleDataToVar("name", "string", false),
          },
        },
      });
    }
  };

  const handleInviteAdmin = () => {
    if (!validateForm(["email"])) {
      return;
    }
    if (type === "create") {
      createPersonRoleSubjectInvitationMutation({
        variables: {
          data: {
            email: inputFields.email.inputProps.value,
          },
        },
      });
    }
  };

  useEffect(() => {
    if (data?.oneAdministrator && open && type === "update") {
      getFormValuesFromFetchedData(
        data.oneAdministrator,
        [
          {
            fromDataProperty: "name",
            toFormProperty: "name",
          },
          //TODO: Email update?
        ],
        false
      );
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, open]);

  return (
    <Dialog
      maxWidth="sm"
      fullWidth
      open={open}
      onClose={onClose}
      TransitionProps={{ onExited: clearFields }}
    >
      <DialogTitle>
        {type === "update"
          ? "Uredi administratora"
          : "Pozovi novog administratora"}
      </DialogTitle>
      <DialogContent>
        <DataHandlerComponent
          loading={loading}
          error={Boolean(error)}
          skeletonHeight={60}
          skeletonNum={2}
          hasData={Boolean(
            type === "update" ? data?.oneAdministrator : true //dataAdminRoles?.allAdminRoles.length
          )}
        >
          <>
            {type === "create" ? (
              <Typography color="textSecondary">
                Kada pozovete novog administratora, pozivnica će biti poslana na
                navedeni email.
              </Typography>
            ) : null}

            {type === "create" ? (
              <TextField
                {...inputFields.email.inputProps}
                margin="normal"
                fullWidth
              />
            ) : (
              <TextField
                {...inputFields.name.inputProps}
                margin="normal"
                autoFocus
                fullWidth
              />
            )}
          </>
        </DataHandlerComponent>
      </DialogContent>

      <DialogActions>
        <Button variant="text" color="primary" onClick={onClose}>
          Odustani
        </Button>
        <Button
          disabled={!didValuesChange(type === "update" ? ["name"] : ["email"])}
          variant="contained"
          color="primary"
          onClick={type === "update" ? handleUpdateAdmin : handleInviteAdmin}
        >
          {type === "update" ? "Uredi" : "Pozovi"}
        </Button>
      </DialogActions>
      <LoadingBackdrop
        loading={loadingUpdateAdminMutation || loadingCreateInvitationMutation}
      />
    </Dialog>
  );
};
