import "./styles/fonts.css";
import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import createCache from "@emotion/cache";
import { CacheProvider, ThemeProvider } from "@emotion/react";
import { theme } from "./styles/theme";
import { CssBaseline } from "@mui/material";
import { SnackbarProvider } from "notistack";
import { ApolloProvider } from "@apollo/client";
import { apolloClient } from "./apollo";
import { PlateController } from "@udecode/plate-common";

export const muiCache = createCache({
  key: "mui",
  prepend: true,
});

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <CacheProvider value={muiCache}>
      <ThemeProvider theme={theme}>
        <SnackbarProvider maxSnack={6}>
          <CssBaseline>
            <ApolloProvider client={apolloClient}>
              <PlateController>
                <App />
              </PlateController>
            </ApolloProvider>
          </CssBaseline>
        </SnackbarProvider>
      </ThemeProvider>
    </CacheProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
