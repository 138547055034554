import { gql } from "@apollo/client";
import { F_PERSON } from "../../fragments";

export interface ICurrentUser {
  id: string;
  name: string;
  email: string;
}

export interface ICurrentUserData {
  currentUser: ICurrentUser;
}

export const CURRENT_USER = gql`
  ${F_PERSON}
  query currentUser {
    currentUser {
      ...FPerson
    }
  }
`;
